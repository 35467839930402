/************************************************
* Kontaktinfos 4-spaltig (Cluster Layout 100)
*************************************************/

.contactInfo4Col {
	
	@include span(full);
	margin-bottom: 7rem;
	background-color: $bg-grey;
	padding:3rem;

	.boxes{
		@include span(full);
	}

	h1, h2, h3 {
		text-align: center;
	}

	.box {
		@include span(3 of 12);
		background-color: white;
		padding: 2rem;
		margin-bottom: 2rem;

		&:nth-of-type(4n) {
			@include span(3 of 12 last);
		}

		p {
			font-weight: 500;
			color: $first;
			text-align: center;
		}

	}

}

/************************************************
* RESPONSIVE
*************************************************/

@media (max-width:1000px) {
	.contactInfo4Col {
		.box {

			@include span(6 of 12);

			&:nth-of-type(2n) {
				@include last;
			}

			&:nth-of-type(4n) {
				@include span(6 of 12 last);
			}

		}

	}
}

@media (max-width:600px) {
	.contactInfo4Col {
		margin:5rem 0;

		.box {
			padding: 2.5rem;
		}
	}
}

@media (max-width: 550px) {
	.contactInfo4Col {
		.box {

			@include span(full);

			&:nth-of-type(2n) {
				@include full;
			}

			&:nth-of-type(4n) {
				@include span(full);
			}

		}

	}
}