/**********************************
* Flexnav Base Stylings
**********************************/

	ul.flexnav {

	  transition: none;
 	  -webkit-transform-style: preserve-3d; // stop webkit flicker
  	  overflow: visible;
	  margin: 0 auto;
	  padding: 0;
	  width: 100%;
	  max-height: 0;
	  list-style: none;
	  position: relative;
	  z-index: 2;
	  
	  &.opacity {
	  	opacity: 1;
	  }

		/* generelle Stylings */

		li {
			position: relative;
			float: left;
			display: block;
			overflow: visible;
			margin: 0;
			list-style: none;
		}

		a {
			position: relative;
			display: block;
			z-index: 2;
			overflow: hidden;
			text-decoration: none;
			width: 100%;
			white-space: nowrap;
		}

		/* erste Ebene */

		> li {

			> a {
			}

		}

		/* zweite Ebene */

		> li > ul {

			margin: 0;
			padding: 0;
			position: absolute; 
			top: auto;
			left: 0;
			width: auto;
			display: none;
			
			> li {
				width: 100%;
				position: relative;

				> a {
					width: 100%;
				}

			}

		}

		/* dritte Ebene */

		> li > ul > li > ul {

			margin: 0;
			padding: 0;
			position: absolute; 
			top: 0;
			left: 100%;
			width: auto;
			display: none;
			
			> li {
				width: 100%;
				position: relative;

				a {
					width: 100%;
				}

			}

		}

	}

	.menu-button {
		display: none;
	}

/* $flexNavBreakPoint is set in base/_settings.scss */

@media all and (max-width: 1100px) {

	
	header#mainPage ul.flexnav {

		overflow: hidden;
		max-height: 0;

		&.opacity {
		  opacity: 0;
		}

		&.flexnav-show {
			max-height: 2000px;
	    opacity: 1;
	    transition: all .5s ease-in-out;
		}

		/* generelle Stylings */

		li {
			width: 100%;
		}

		/* zweite Ebene */

		> li > ul.flexnav-show {

			position: relative;
		   
			li {

				overflow: visible;
			}

		}

		/* dritte Ebene */

		> li > ul > li > ul.flexnav-show {

			position: relative;
			left: auto;
		   
			li {

				overflow: visible;
			}

		}

	}

}
