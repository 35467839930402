/************************************************
* General CONTENT Stylings
*************************************************/

.breadcrumbWrapper {
	position: relative;
	width: 100%;
	background-color: $linkPrimary;
	padding: .2rem 0;
}

#breadcrumb {
	@include container(1600px);
	text-align: left;
	width: 100%;

	ol {
		padding-left: 0;
	}

	li {
		display: inline;
		padding: 0.5rem;

		&:first-of-type{
			padding-left: 0;
		}

		a {
			color: white;

			&:after {
				content:" /";
				padding-left:0.5rem;
				padding-right:0.5rem;
			}
		}
	
		> span {
			color: #fff;
			// color: #4ecfa1;
		}

	
	}
}

/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width:2000px) {

		#breadcrumb {
			max-width: 91%;
		}

	}

	@media (max-width:1650px) {

		#breadcrumb {
			max-width: 97.5%;
		}

	}

	@media (max-width:1100px) {

		#breadcrumb {
			max-width: 95%;
		}

	}
