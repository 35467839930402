/************************************************
* Downloadbox (Text / Media - Layout 240)
*************************************************/

.boxesDownload {
	@include span (full);
	margin-bottom: 3rem;
	background-color: $bg-grey;
	padding: 1rem 4rem;

	// h2 {
	// 	flex: 1 0 100%;
	// }

	.boxes {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		&::after {
		  content: "";
		  flex: auto;
		}		

	}

	.box {
		// @include span (3 of 12);
		flex: 0 1 23.4%;
		margin: .8%;
		min-width: 300px;
		overflow: auto;
		display: block;
		background-color:white;
		padding: 1rem 2rem;
		transition: color .4s ease, background-color .4s ease;
		font-size: .9em;

		// &:nth-of-type(4n) {
		// 	@include span (3 of 12 last);
		// }

		img {
			border: 1px solid #ddd;
		}

		&:hover {
			background-color: $linkPrimary;

			p {
				color:white;
			}

			i {
				color:white;
			}
		}
	}

	h2, h3 {
		text-align: center;
		margin: 1.5rem 0;
		&.closed,
		&.open {
			cursor: pointer;
		}
	}

	i {
		font-size:200%;
		float: left;
		display: inline-block;
		width: 20%;
		padding-right: 4%;
	}

	p {
		margin: .2rem 0;
		float: left;
		display: inline-block;
		width: 76%;
		line-height: 1.8rem;
	}

}

 /************************************************
 * RESPONSIVE
 ************************************************/

 @media (max-width:1024px) {

 		.boxesDownload {
 			.box {
 				&:hover {
 					background-color: white;

 					p {
 						color: $textPrimary;
 					}
 					i {
 						color: $linkPrimary;
 					}
 				}
 			}
 		}

 }

 @media (max-width:900px) {

 		.boxesDownload {
 			.box {
 				margin-bottom: 1rem;

 			}
 		}

 }

  @media (max-width:600px) {

		.boxesDownload {
		padding: 2.5rem;

			.box {
				margin-bottom: 1rem;
			}
		}

 	}
