/************************************************
* lang Switch
*************************************************/

#language {

  float: left;
  margin-right: 1.3rem;
  margin-top: 1.3rem;
  /*
  position: absolute;
  right: 16rem;
  bottom: 1.2rem;
  z-index: 10;
  */
  text-transform: uppercase;

  a,
  span {
    display: inline-block;
    padding: .5rem .3rem;
  }
  &> span:not(.current),
  &> a {
    opacity: .4;
  }
  a:hover {
    opacity: .8;
  }
  a.inactive {
    opacity: .2;
  }
  .current {
    opacity: 1;
  }
  .unavailable {
    display: none;
  }


  &> span {
    float:left; 
    width: 30px;
    height: 15px;
    background-size: cover;
    display: block;
    padding: 0;
    margin-right: .4em;
  }

  a {
    background-size: cover;
    display: block;
    width: inherit;
    height: inherit;
  }

  span.de {
    background-image: url(/fileadmin/ege/img/de.svg);
  }
  span.en {
    background-image: url(/fileadmin/ege/img/en.svg);
  }
  span.fr {
    background-image: url(/fileadmin/ege/img/fr.svg);
  }
}

/************************************************
* RESPONSIVE
*************************************************/

@media (max-width: 1024px) {
  #language span {
    padding: .5rem 1rem;
  }
}

@media (max-width: 360px) {
  #language {
    margin-right: 1rem;
  }
}

@media (max-width: 340px) {
  #language {
    margin-right: .2rem;
  }
}