/************************************************
* Iconauflistung 2-spaltig (Cluster Layout 80)
*************************************************/

.iconList2Col {
	
	@include span(full);
	margin-bottom: 3rem;
	background-color: $bg-grey;
	padding:2rem 4rem;

	.boxes{
		@include pre(2 of 12);
		@include span(8 of 12);
		@include post(2 of 12);
	}

	h1, h2, h3 {
		@include pre(2 of 12);
		@include span(8 of 12);
		@include post(2 of 12);
	}

	.box {
		@include span(6 of 12);
		margin-bottom: 2rem;
		min-height: 8rem;

		&:nth-of-type(2n) {
			@include span(6 of 12 last);
		}

		figure {
			position: absolute;
			background-color: $linkPrimary;
			border-radius:50%;
			width:7rem;
			height:7rem;
			margin: 5px;
			transition: all .3s ease;
			padding:1rem;
		}

		img {
			width:55%;
			height: auto;
			top: 50%;
			left:50%;
			transform:translate(-50%, -50%);
			position:absolute;
		}

		.text {
			padding-left: 9rem;
		}
	}

}

.iconList2Col.noColor .box {
	figure {
	  background-color: inherit;
	  padding: 0;
    }
    img {
    	width: 100%;
    	transform: inherit;
    	top: inherit;
    	left: inherit;
    }
}

/*****************
* RESPONSIVE
******************/

@media (max-width:1100px) {

	.iconList2Col {

		h1, h2, h3 {
			@include span(full);
			@include pre(0);
			@include post(0);
		}

		.boxes {
			@include span(full);
			@include pre(0);
			@include post(0);
			margin-bottom:2rem;
		}

		.box {
			.figure {
				width:6rem;
				height: 6rem;
			}
		}

	}
}

@media (max-width:750px) {
	
	.iconList2Col {
		padding:2rem;
		
		.boxes {
			@include span(full);
			margin-bottom:2rem;
		}

		.box {
			@include span(full);

			&:nth-of-type(2n) {
				@include span(full);
			}

		}

	}
}