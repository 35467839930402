@charset "UTF-8";
/************************************************
* SETUP
*************************************************/
/* clearfix */
/* Breakpoints */
/************************************************
* theme Settings
*************************************************/
/* flexNav breakpoint (max-width) */
/**
 * Susy Config
 */
/************************************************
* Base Definitions
*************************************************/
/*****************
* Colors
******************/
/************************************************
* FONT
*************************************************/
/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/source-sans-pro-v21-latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/source-sans-pro-v21-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v21-latin-300.woff2") format("woff2"), url("../fonts/source-sans-pro-v21-latin-300.woff") format("woff"), url("../fonts/source-sans-pro-v21-latin-300.ttf") format("truetype"), url("../fonts/source-sans-pro-v21-latin-300.svg#SourceSansPro") format("svg");
  /* Legacy iOS */ }

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/source-sans-pro-v21-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/source-sans-pro-v21-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v21-latin-regular.woff2") format("woff2"), url("../fonts/source-sans-pro-v21-latin-regular.woff") format("woff"), url("../fonts/source-sans-pro-v21-latin-regular.ttf") format("truetype"), url("../fonts/source-sans-pro-v21-latin-regular.svg#SourceSansPro") format("svg");
  /* Legacy iOS */ }

/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/source-sans-pro-v21-latin-600.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/source-sans-pro-v21-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v21-latin-600.woff2") format("woff2"), url("../fonts/source-sans-pro-v21-latin-600.woff") format("woff"), url("../fonts/source-sans-pro-v21-latin-600.ttf") format("truetype"), url("../fonts/source-sans-pro-v21-latin-600.svg#SourceSansPro") format("svg");
  /* Legacy iOS */ }

/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/source-sans-pro-v21-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/source-sans-pro-v21-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v21-latin-700.woff2") format("woff2"), url("../fonts/source-sans-pro-v21-latin-700.woff") format("woff"), url("../fonts/source-sans-pro-v21-latin-700.ttf") format("truetype"), url("../fonts/source-sans-pro-v21-latin-700.svg#SourceSansPro") format("svg");
  /* Legacy iOS */ }

/************************************************
* Base Font Setting
*************************************************/
html {
  font-size: 62.5%; }

body {
  font-family: 'Source Sans Pro', sans-serif; }

/************************************************
 * TYPO
 ************************************************/
h1 {
  font-size: 2.6rem;
  font-weight: 600;
  color: #555;
  position: relative;
  margin: 3rem 0; }
  h1::after {
    content: '';
    position: absolute;
    border-bottom: 2px solid #028a5a;
    width: 7rem;
    bottom: 0;
    left: 0;
    top: 3.5rem;
    margin-top: 0.7rem; }

h2 {
  font-size: 2.2rem;
  font-weight: 600;
  color: #555; }

h3 {
  font-size: 1.9rem;
  font-weight: 600;
  color: #555; }

h4 {
  font-size: 1.6rem;
  font-weight: 400;
  color: #555; }

p {
  font-size: 1.6rem;
  font-weight: 400;
  color: #808080;
  line-height: 1.5; }
  p.small {
    font-size: 1.2rem;
    font-weight: 300; }
  p.big {
    font-size: 1.8rem;
    font-weight: 600; }
    p.big a {
      font-size: 1.8rem;
      font-weight: 600; }
  p.indent {
    padding-left: 2rem; }

strong {
  font-weight: 600; }

a {
  font-size: 1.6rem;
  font-weight: 400;
  text-decoration: none;
  color: #028a5a;
  /* links to files */ }
  a:hover {
    color: #555; }
  a.filelink {
    position: relative;
    padding-left: 2rem; }
    a.filelink::before {
      position: absolute;
      left: 0;
      top: .1rem; }
    a.filelink[href$=".txt"]::before {
      content: '\f0f6'; }
    a.filelink[href$=".pdf"]::before {
      content: '\f1c1'; }
    a.filelink[href$=".doc"]::before, a.filelink[href$=".docx"]::before {
      content: '\f1c2'; }
    a.filelink[href$=".xls"]::before, a.filelink[href$=".xlsx"]::before {
      content: '\f1c3'; }
    a.filelink[href$=".ppt"]::before, a.filelink[href$=".pptx"]::before {
      content: '\f1c4'; }
    a.filelink[href$=".jpg"]::before {
      content: '\f1c5'; }
    a.filelink[href$=".zip"]::before {
      content: '\f1c6'; }

/* anchor links */
main a[href^='#']::after {
  content: '\f149'; }

a.anchorTarget {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

li {
  font-size: 1.6rem;
  font-weight: 400;
  color: #808080; }

span {
  font-size: 1.6rem;
  font-weight: 400; }

ul.arrow {
  list-style-type: none;
  padding: 0; }
  ul.arrow li {
    line-height: 1.5;
    padding-left: 2rem;
    position: relative;
    margin: 0; }
    ul.arrow li:before {
      position: absolute;
      left: .5rem;
      top: .6rem;
      content: '\e80f';
      color: #028a5a;
      transition: all ease .5s;
      font-size: 1rem; }

/* RTE Editor classes */
.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

/************************************************
* RESPONSIVE
************************************************/
@media (max-width: 1024px) {
  h1 {
    font-size: 2.4rem; } }

@media (max-width: 768px) {
  h2 {
    font-size: 2.1rem;
    font-weight: 600; }
  h3 {
    font-size: 1.8rem;
    font-weight: 600; } }

@media (max-width: 480px) {
  h1 {
    font-size: 2.2rem; }
    h1::after {
      top: 3rem; } }

/************************************************
* ICONS (fontello.com)
*************************************************/
@font-face {
  font-family: 'ege-icons';
  src: url("../fonts/ege-icons.eot?79984106");
  src: url("../fonts/ege-icons.eot?79984106#iefix") format("embedded-opentype"), url("../fonts/ege-icons.woff2?79984106") format("woff2"), url("../fonts/ege-icons.woff?79984106") format("woff"), url("../fonts/ege-icons.ttf?79984106") format("truetype"), url("../fonts/ege-icons.svg?79984106#ege-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

a.filelink::before, main a[href^='#']::after, ul.arrow li:before, [class^="icon-"]:before,
[class*=" icon-"]:before, main ul:not(.select2-selection__rendered) li:before, .menu-button::before, .boxesFeature4ColReferences .arrow::after, .textStandard li::before, main .boxCategories ul li a::before, .formElement .fileWrapper label:before, .select2-container--default .select2-selection--single .select2-selection__arrow b::after, button.more-btn::after,
a.button.more-btn::after, .newsDetail a[href^='https://www.youtube.']::after,
.newsDetail a[href^='https://youtube.']::after, footer .upper .column li:before, footer .lower .social a::after {
  font-family: "ege-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none; }

.icon-comment:before {
  content: '\e800'; }

/* '' */
.icon-user:before {
  content: '\e801'; }

/* '' */
.icon-users:before {
  content: '\e802'; }

/* '' */
.icon-basket:before {
  content: '\e803'; }

/* '' */
.icon-door:before {
  content: '\e804'; }

/* '' */
.icon-shield:before {
  content: '\e805'; }

/* '' */
.icon-quality-badge:before {
  content: '\e806'; }

/* '' */
.icon-folder:before {
  content: '\e807'; }

/* '' */
.icon-phone-call:before {
  content: '\e808'; }

/* '' */
.icon-placeholder:before {
  content: '\e809'; }

/* '' */
.icon-clock:before {
  content: '\e80a'; }

/* '' */
.icon-speech-bubble:before {
  content: '\e80b'; }

/* '' */
.icon-shopping-cart-of-checkered-design:before {
  content: '\e80c'; }

/* '' */
.icon-magnifier:before {
  content: '\e80d'; }

/* '' */
.icon-cloud-computing:before {
  content: '\e80e'; }

/* '' */
.icon-right-open-big:before {
  content: '\e80f'; }

/* '' */
.icon-right-thin:before {
  content: '\e810'; }

/* '' */
.icon-user-1:before {
  content: '\e811'; }

/* '' */
.icon-play-circled2:before {
  content: '\e812'; }

/* '' */
.icon-play:before {
  content: '\e813'; }

/* '' */
.icon-facebook:before {
  content: '\f09a'; }

/* '' */
.icon-certificate:before {
  content: '\f0a3'; }

/* '' */
.icon-menu:before {
  content: '\f0c9'; }

/* '' */
.icon-doc-text:before {
  content: '\f0f6'; }

/* '' */
.icon-angle-left:before {
  content: '\f104'; }

/* '' */
.icon-angle-right:before {
  content: '\f105'; }

/* '' */
.icon-angle-up:before {
  content: '\f106'; }

/* '' */
.icon-angle-down:before {
  content: '\f107'; }

/* '' */
.icon-play-circled:before {
  content: '\f144'; }

/* '' */
.icon-level-down:before {
  content: '\f149'; }

/* '' */
.icon-xing:before {
  content: '\f168'; }

/* '' */
.icon-file-pdf:before {
  content: '\f1c1'; }

/* '' */
.icon-file-word:before {
  content: '\f1c2'; }

/* '' */
.icon-file-excel:before {
  content: '\f1c3'; }

/* '' */
.icon-file-powerpoint:before {
  content: '\f1c4'; }

/* '' */
.icon-file-image:before {
  content: '\f1c5'; }

/* '' */
.icon-file-archive:before {
  content: '\f1c6'; }

/* '' */
.icon-gplus:before {
  content: '\f30f'; }

/* '' */
/************************************************
* natural box-sizing
*************************************************/
*, *:after, *:before {
  box-sizing: border-box; }

/************************************************
* responsive images
*************************************************/
img {
  max-width: 100%;
  height: auto; }

/************************************************
* general CSS Stylings
*************************************************/
html, body {
  margin: 0;
  scroll-behavior: smooth; }

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important; } }

body > div > header,
main {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  display: block; }
  body > div > header:after,
  main:after {
    content: " ";
    display: block;
    clear: both; }

main header {
  clear: both;
  overflow: auto;
  margin-bottom: 3rem; }
  main header h2 {
    text-align: center; }

.leftCol {
  width: 74.57627%;
  float: left;
  margin-right: 1.69492%; }

.rightCol {
  width: 23.72881%;
  float: right;
  margin-right: 0; }

main ul:not(.select2-selection__rendered) {
  list-style-type: none; }
  main ul:not(.select2-selection__rendered) li {
    text-align: left;
    line-height: 1.5;
    padding-left: 2rem;
    position: relative; }
    main ul:not(.select2-selection__rendered) li:before {
      position: absolute;
      left: .5rem;
      top: .6rem;
      content: '\e80f';
      color: #028a5a;
      transition: all ease .5s;
      font-size: 1rem; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 2000px) {
  body > div > header,
  main {
    max-width: 84%; } }

@media (max-width: 1650px) {
  body > div > header,
  main {
    max-width: 90%; } }

@media (max-width: 1400px) {
  body > div > header {
    max-width: 95%; }
  main {
    max-width: 93%; } }

@media (max-width: 1100px) {
  body > div > header {
    max-width: 100%; }
  main {
    max-width: 95%; }
  .leftCol {
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%; }
  .rightCol {
    width: 32.20339%;
    float: right;
    margin-right: 0; } }

@media (max-width: 600px) {
  .leftCol {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 3rem; }
  .rightCol {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 3rem; } }

/************************************************
* Library's (optional)
*************************************************/
/* 
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out; }

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel .owl-refresh .owl-item {
  display: none; }

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d; }

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto; }

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img {
  transform-style: preserve-3d; }

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1; }

.owl-theme .owl-controls {
  margin-top: 10px;
  text-align: center; }

/* Styling Next and Prev buttons */
.owl-theme .owl-controls .owl-nav div {
  color: #FFF;
  display: inline-block;
  zoom: 1;
  *display: inline;
  /*IE7 life-saver */
  margin: 5px;
  padding: 3px 10px;
  font-size: 12px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: #869791;
  filter: Alpha(Opacity=50);
  /*IE7 fix*/
  opacity: 0.5; }

/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-nav div:hover {
  filter: Alpha(Opacity=100);
  /*IE7 fix*/
  opacity: 1;
  text-decoration: none; }

/* Styling Pagination*/
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
  /*IE7 life-saver */ }

.owl-theme .owl-dots .owl-dot span {
  display: block;
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  filter: Alpha(Opacity=50);
  /*IE7 fix*/
  opacity: 1;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #869791; }

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots.clickable .owl-dot:hover span {
  filter: Alpha(Opacity=70);
  /*IE7 fix*/
  opacity: 0.7; }

/* If PaginationNumbers is true */
.owl-theme .owl-dots .owl-dot span.owl-numbers {
  height: auto;
  width: auto;
  color: #FFF;
  padding: 2px 10px;
  font-size: 12px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px; }

/* preloading images */
.owl-item.loading {
  min-height: 150px;
  background: url(../../img/AjaxLoader.gif) no-repeat center center; }

/**
 * Swiper 3.4.2
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 * 
 * http://www.idangero.us/swiper/
 * 
 * Copyright 2017, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 * 
 * Licensed under MIT
 * 
 * Released on: March 10, 2017
 */
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative; }

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height; }

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat; }

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto; }

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0; }

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

/* Bullets */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0); }

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px; }

/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top; }

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top; }

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0; }

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5); }

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff; }

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000; }

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px; }

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10; }

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, left, right);
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, left, right);
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, left, right);
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, left, right);
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px; }

/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible; }

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1; }

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden; }

/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0; }

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity; }

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center; }

.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; }

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

/**********************************
* Flexnav Base Stylings
**********************************/
ul.flexnav {
  transition: none;
  -webkit-transform-style: preserve-3d;
  overflow: visible;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-height: 0;
  list-style: none;
  position: relative;
  z-index: 2;
  /* generelle Stylings */
  /* erste Ebene */
  /* zweite Ebene */
  /* dritte Ebene */ }
  ul.flexnav.opacity {
    opacity: 1; }
  ul.flexnav li {
    position: relative;
    float: left;
    display: block;
    overflow: visible;
    margin: 0;
    list-style: none; }
  ul.flexnav a {
    position: relative;
    display: block;
    z-index: 2;
    overflow: hidden;
    text-decoration: none;
    width: 100%;
    white-space: nowrap; }
  ul.flexnav > li > ul {
    margin: 0;
    padding: 0;
    position: absolute;
    top: auto;
    left: 0;
    width: auto;
    display: none; }
    ul.flexnav > li > ul > li {
      width: 100%;
      position: relative; }
      ul.flexnav > li > ul > li > a {
        width: 100%; }
  ul.flexnav > li > ul > li > ul {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 100%;
    width: auto;
    display: none; }
    ul.flexnav > li > ul > li > ul > li {
      width: 100%;
      position: relative; }
      ul.flexnav > li > ul > li > ul > li a {
        width: 100%; }

.menu-button {
  display: none; }

/* $flexNavBreakPoint is set in base/_settings.scss */
@media all and (max-width: 1100px) {
  header#mainPage ul.flexnav {
    overflow: hidden;
    max-height: 0;
    /* generelle Stylings */
    /* zweite Ebene */
    /* dritte Ebene */ }
    header#mainPage ul.flexnav.opacity {
      opacity: 0; }
    header#mainPage ul.flexnav.flexnav-show {
      max-height: 2000px;
      opacity: 1;
      transition: all .5s ease-in-out; }
    header#mainPage ul.flexnav li {
      width: 100%; }
    header#mainPage ul.flexnav > li > ul.flexnav-show {
      position: relative; }
      header#mainPage ul.flexnav > li > ul.flexnav-show li {
        overflow: visible; }
    header#mainPage ul.flexnav > li > ul > li > ul.flexnav-show {
      position: relative;
      left: auto; }
      header#mainPage ul.flexnav > li > ul > li > ul.flexnav-show li {
        overflow: visible; } }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative; }

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-search--inline {
  float: left; }

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0; }

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

.select2-results__option[aria-selected] {
  cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box; }

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-search--dropdown.select2-search--hide {
  display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none; }

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left; }

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px; }

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

.fancybox-enabled {
  overflow: hidden; }

.fancybox-enabled body {
  overflow: visible;
  height: 100%; }

.fancybox-is-hidden {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden; }

.fancybox-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99993;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

/* Make sure that the first one is on the top */
.fancybox-container ~ .fancybox-container {
  z-index: 99992; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.fancybox-outer {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: 0.87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption-wrap {
  position: absolute;
  direction: ltr;
  z-index: 99997;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s, visibility 0s linear .25s;
  box-sizing: border-box; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption-wrap {
  opacity: 1;
  visibility: visible;
  transition: opacity .25s, visibility 0s; }

.fancybox-infobar {
  top: 0;
  left: 50%;
  margin-left: -79px; }

.fancybox-infobar__body {
  display: inline-block;
  width: 70px;
  line-height: 44px;
  font-size: 13px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  text-align: center;
  color: #ddd;
  background-color: rgba(30, 30, 30, 0.7);
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased; }

.fancybox-toolbar {
  top: 0;
  right: 0; }

.fancybox-stage {
  overflow: hidden;
  direction: ltr;
  z-index: 99994;
  -webkit-transform: translate3d(0, 0, 0); }

.fancybox-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  white-space: normal;
  box-sizing: border-box;
  text-align: center;
  z-index: 99994;
  -webkit-overflow-scrolling: touch;
  display: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--image {
  overflow: visible; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--video iframe {
  background: #000; }

.fancybox-slide--map .fancybox-content,
.fancybox-slide--map iframe {
  background: #E5E3DF; }

.fancybox-slide--next {
  z-index: 99995; }

.fancybox-slide > * {
  display: inline-block;
  position: relative;
  padding: 24px;
  margin: 44px 0 44px;
  border-width: 0;
  vertical-align: middle;
  text-align: left;
  background-color: #fff;
  overflow: auto;
  box-sizing: border-box; }

.fancybox-slide .fancybox-image-wrap {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 99995;
  background: transparent;
  cursor: default;
  overflow: visible;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.fancybox-can-zoomOut .fancybox-image-wrap {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-image-wrap {
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.fancybox-can-drag .fancybox-image-wrap {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-dragging .fancybox-image-wrap {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-image,
.fancybox-spaceball {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  max-height: none; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--iframe .fancybox-content {
  padding: 0;
  width: 80%;
  height: 80%;
  max-width: calc(100% - 100px);
  max-height: calc(100% - 88px);
  overflow: visible;
  background: #fff; }

.fancybox-iframe {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  background: #fff; }

.fancybox-error {
  margin: 0;
  padding: 40px;
  width: 100%;
  max-width: 380px;
  background: #fff;
  cursor: default; }

.fancybox-error p {
  margin: 0;
  padding: 0;
  color: #444;
  font: 16px/20px "Helvetica Neue",Helvetica,Arial,sans-serif; }

.fancybox-close-small {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  outline: none;
  background: transparent;
  z-index: 10;
  cursor: pointer; }

.fancybox-close-small:after {
  content: '×';
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  font: 20px/30px Arial,"Helvetica Neue",Helvetica,sans-serif;
  color: #888;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  border-width: 0;
  background: #fff;
  transition: background .25s;
  box-sizing: border-box;
  z-index: 2; }

.fancybox-close-small:focus:after {
  outline: 1px dotted #888; }

.fancybox-close-small:hover:after {
  color: #555;
  background: #eee; }

.fancybox-slide--iframe .fancybox-close-small {
  top: 0;
  right: -44px; }

.fancybox-slide--iframe .fancybox-close-small:after {
  background: transparent;
  font-size: 35px;
  color: #aaa; }

.fancybox-slide--iframe .fancybox-close-small:hover:after {
  color: #fff; }

/* Caption */
.fancybox-caption-wrap {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px 30px 0 30px;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.8) 100%);
  pointer-events: none; }

.fancybox-caption {
  padding: 30px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 14px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #fff;
  line-height: 20px;
  -webkit-text-size-adjust: none; }

.fancybox-caption a,
.fancybox-caption button,
.fancybox-caption select {
  pointer-events: all; }

.fancybox-caption a {
  color: #fff;
  text-decoration: underline; }

/* Buttons */
.fancybox-button {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: transparent;
  color: #ddd;
  border-radius: 0;
  cursor: pointer;
  vertical-align: top;
  outline: none; }

.fancybox-button[disabled] {
  cursor: default;
  pointer-events: none; }

.fancybox-infobar__body, .fancybox-button {
  background: rgba(30, 30, 30, 0.6); }

.fancybox-button:hover:not([disabled]) {
  color: #fff;
  background: rgba(0, 0, 0, 0.8); }

.fancybox-button::before,
.fancybox-button::after {
  content: '';
  pointer-events: none;
  position: absolute;
  background-color: currentColor;
  color: currentColor;
  opacity: 0.9;
  box-sizing: border-box;
  display: inline-block; }

.fancybox-button[disabled]::before,
.fancybox-button[disabled]::after {
  opacity: 0.3; }

.fancybox-button--left::after,
.fancybox-button--right::after {
  top: 18px;
  width: 6px;
  height: 6px;
  background: transparent;
  border-top: solid 2px currentColor;
  border-right: solid 2px currentColor; }

.fancybox-button--left::after {
  left: 20px;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg); }

.fancybox-button--right::after {
  right: 20px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.fancybox-button--left {
  border-bottom-left-radius: 5px; }

.fancybox-button--right {
  border-bottom-right-radius: 5px; }

.fancybox-button--close::before, .fancybox-button--close::after {
  content: '';
  display: inline-block;
  position: absolute;
  height: 2px;
  width: 16px;
  top: calc(50% - 1px);
  left: calc(50% - 8px); }

.fancybox-button--close::before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.fancybox-button--close::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

/* Navigation arrows */
.fancybox-arrow {
  position: absolute;
  top: 50%;
  margin: -50px 0 0 0;
  height: 100px;
  width: 54px;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;
  z-index: 99995;
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: opacity .25s; }

.fancybox-arrow::after {
  content: '';
  position: absolute;
  top: 28px;
  width: 44px;
  height: 44px;
  background-color: rgba(30, 30, 30, 0.8);
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGRkZGIiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSI0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPiAgICA8cGF0aCBkPSJNMTIgNGwtMS40MSAxLjQxTDE2LjE3IDExSDR2MmgxMi4xN2wtNS41OCA1LjU5TDEyIDIwbDgtOHoiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px 24px; }

.fancybox-arrow--right {
  right: 0; }

.fancybox-arrow--left {
  left: 0;
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1); }

.fancybox-arrow--right::after,
.fancybox-arrow--left::after {
  left: 0; }

.fancybox-show-nav .fancybox-arrow {
  opacity: 0.6; }

.fancybox-show-nav .fancybox-arrow[disabled] {
  opacity: 0.3; }

/* Loading indicator */
.fancybox-loading {
  border: 6px solid rgba(100, 100, 100, 0.4);
  border-top: 6px solid rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  -webkit-animation: fancybox-rotate .8s infinite linear;
  animation: fancybox-rotate .8s infinite linear;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 99999; }

@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  opacity: 0; }

.fancybox-fx-slide.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  opacity: 0; }

.fancybox-fx-slide.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5);
  opacity: 0; }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0; }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg);
  opacity: 0; }

.fancybox-fx-rotate.fancybox-slide--next {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
  opacity: 0; }

.fancybox-fx-rotate.fancybox-slide--current {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1; }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  opacity: 0; }

.fancybox-fx-circular.fancybox-slide--next {
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  opacity: 0; }

.fancybox-fx-circular.fancybox-slide--current {
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1); }

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-infobar {
    left: 0;
    margin-left: 0; }
  .fancybox-button--left,
  .fancybox-button--right {
    display: none !important; }
  .fancybox-caption {
    padding: 20px 0;
    margin: 0; } }

/* Fullscreen  */
.fancybox-button--fullscreen::before {
  width: 15px;
  height: 11px;
  left: calc(50% - 7px);
  top: calc(50% - 6px);
  border: 2px solid;
  background: none; }

/* Slideshow button */
.fancybox-button--play::before,
.fancybox-button--pause::before {
  top: calc(50% - 6px);
  left: calc(50% - 4px);
  background: transparent; }

.fancybox-button--play::before {
  width: 0;
  height: 0;
  border-top: 6px inset transparent;
  border-bottom: 6px inset transparent;
  border-left: 10px solid;
  border-radius: 1px; }

.fancybox-button--pause::before {
  width: 7px;
  height: 11px;
  border-style: solid;
  border-width: 0 2px 0 2px; }

/* Thumbs */
.fancybox-thumbs {
  display: none; }

.fancybox-button--thumbs {
  display: none; }

@media all and (min-width: 800px) {
  .fancybox-button--thumbs {
    display: inline-block; }
  .fancybox-button--thumbs span {
    font-size: 23px; }
  .fancybox-button--thumbs::before {
    width: 3px;
    height: 3px;
    top: calc(50% - 2px);
    left: calc(50% - 2px);
    box-shadow: 0 -4px 0, -4px -4px 0, 4px -4px 0, 0 0 0 32px inset, -4px 0 0, 4px 0 0, 0 4px 0, -4px 4px 0, 4px 4px 0; }
  .fancybox-thumbs {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    width: 220px;
    margin: 0;
    padding: 5px 5px 0 0;
    background: #fff;
    word-break: normal;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    box-sizing: border-box;
    z-index: 99995; }
  .fancybox-show-thumbs .fancybox-thumbs {
    display: block; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 220px; }
  .fancybox-thumbs > ul {
    list-style: none;
    position: absolute;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 0; }
  .fancybox-thumbs > ul > li {
    float: left;
    overflow: hidden;
    max-width: 50%;
    padding: 0;
    margin: 0;
    width: 105px;
    height: 75px;
    position: relative;
    cursor: pointer;
    outline: none;
    border: 5px solid transparent;
    border-top-width: 0;
    border-right-width: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-sizing: border-box; }
  li.fancybox-thumbs-loading {
    background: rgba(0, 0, 0, 0.1); }
  .fancybox-thumbs > ul > li > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: none;
    max-height: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .fancybox-thumbs > ul > li:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 2px;
    border: 4px solid #4ea7f9;
    z-index: 99991;
    opacity: 0;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  .fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
    opacity: 1; } }

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede; }

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5; }

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede; }

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6; }

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4; }

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc; }

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2); }

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none; }

/* -------------------------------------------------
    Datepicker cells
   ------------------------------------------------- */
.datepicker--cells {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.datepicker--cell {
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 32px;
  z-index: 1; }

.datepicker--cell.-focus- {
  background: #f0f0f0; }

.datepicker--cell.-current- {
  color: #4EB5E6; }

.datepicker--cell.-current-.-focus- {
  color: #4a4a4a; }

.datepicker--cell.-current-.-in-range- {
  color: #4EB5E6; }

.datepicker--cell.-in-range- {
  background: rgba(92, 196, 239, 0.1);
  color: #4a4a4a;
  border-radius: 0; }

.datepicker--cell.-in-range-.-focus- {
  background-color: rgba(92, 196, 239, 0.2); }

.datepicker--cell.-disabled- {
  cursor: default;
  color: #aeaeae; }

.datepicker--cell.-disabled-.-focus- {
  color: #aeaeae; }

.datepicker--cell.-disabled-.-in-range- {
  color: #a1a1a1; }

.datepicker--cell.-disabled-.-current-.-focus- {
  color: #aeaeae; }

.datepicker--cell.-range-from- {
  border: 1px solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 4px 0 0 4px; }

.datepicker--cell.-range-to- {
  border: 1px solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 0 4px 4px 0; }

.datepicker--cell.-range-from-.-range-to- {
  border-radius: 4px; }

.datepicker--cell.-selected- {
  color: #fff;
  border: none;
  background: #5cc4ef; }

.datepicker--cell.-selected-.-current- {
  color: #fff;
  background: #5cc4ef; }

.datepicker--cell.-selected-.-focus- {
  background: #45bced; }

.datepicker--cell:empty {
  cursor: default; }

.datepicker--days-names {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 8px 0 3px; }

.datepicker--day-name {
  color: #FF9A19;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: .8em; }

.datepicker--cell-day {
  width: 14.28571%; }

.datepicker--cells-months {
  height: 170px; }

.datepicker--cell-month {
  width: 33.33%;
  height: 25%; }

.datepicker--years {
  height: 170px; }

.datepicker--cells-years {
  height: 170px; }

.datepicker--cell-year {
  width: 25%;
  height: 33.33%; }

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede; }

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5; }

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede; }

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6; }

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4; }

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc; }

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2); }

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none; }

/* -------------------------------------------------
    Datepicker
   ------------------------------------------------- */
.datepickers-container {
  position: absolute;
  left: 0;
  top: 0; }

@media print {
  .datepickers-container {
    display: none; } }

.datepicker {
  background: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-sizing: content-box;
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  width: 250px;
  position: absolute;
  left: -100000px;
  opacity: 0;
  transition: opacity 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  z-index: 100; }

.datepicker.-from-top- {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px); }

.datepicker.-from-right- {
  -webkit-transform: translateX(8px);
  transform: translateX(8px); }

.datepicker.-from-bottom- {
  -webkit-transform: translateY(8px);
  transform: translateY(8px); }

.datepicker.-from-left- {
  -webkit-transform: translateX(-8px);
  transform: translateX(-8px); }

.datepicker.active {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  transition: opacity 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease; }

.datepicker-inline .datepicker {
  border-color: #d7d7d7;
  box-shadow: none;
  position: static;
  left: auto;
  right: auto;
  opacity: 1;
  -webkit-transform: none;
  transform: none; }

.datepicker-inline .datepicker--pointer {
  display: none; }

.datepicker--content {
  box-sizing: content-box;
  padding: 4px; }

.-only-timepicker- .datepicker--content {
  display: none; }

.datepicker--pointer {
  position: absolute;
  background: #fff;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  width: 10px;
  height: 10px;
  z-index: -1; }

.-top-left- .datepicker--pointer, .-top-center- .datepicker--pointer, .-top-right- .datepicker--pointer {
  top: calc(100% - 4px);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg); }

.-right-top- .datepicker--pointer, .-right-center- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  right: calc(100% - 4px);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg); }

.-bottom-left- .datepicker--pointer, .-bottom-center- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  bottom: calc(100% - 4px);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg); }

.-left-top- .datepicker--pointer, .-left-center- .datepicker--pointer, .-left-bottom- .datepicker--pointer {
  left: calc(100% - 4px);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.-top-left- .datepicker--pointer, .-bottom-left- .datepicker--pointer {
  left: 10px; }

.-top-right- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  right: 10px; }

.-top-center- .datepicker--pointer, .-bottom-center- .datepicker--pointer {
  left: calc(50% - 10px / 2); }

.-left-top- .datepicker--pointer, .-right-top- .datepicker--pointer {
  top: 10px; }

.-left-bottom- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  bottom: 10px; }

.-left-center- .datepicker--pointer, .-right-center- .datepicker--pointer {
  top: calc(50% - 10px / 2); }

.datepicker--body {
  display: none; }

.datepicker--body.active {
  display: block; }

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede; }

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5; }

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede; }

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6; }

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4; }

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc; }

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2); }

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none; }

/* -------------------------------------------------
    Navigation
   ------------------------------------------------- */
.datepicker--nav {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  min-height: 32px;
  padding: 4px; }

.-only-timepicker- .datepicker--nav {
  display: none; }

.datepicker--nav-title,
.datepicker--nav-action {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.datepicker--nav-action {
  width: 32px;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.datepicker--nav-action:hover {
  background: #f0f0f0; }

.datepicker--nav-action.-disabled- {
  visibility: hidden; }

.datepicker--nav-action svg {
  width: 32px;
  height: 32px; }

.datepicker--nav-action path {
  fill: none;
  stroke: #9c9c9c;
  stroke-width: 2px; }

.datepicker--nav-title {
  border-radius: 4px;
  padding: 0 8px; }

.datepicker--nav-title i {
  font-style: normal;
  color: #9c9c9c;
  margin-left: 5px; }

.datepicker--nav-title:hover {
  background: #f0f0f0; }

.datepicker--nav-title.-disabled- {
  cursor: default;
  background: none; }

.datepicker--buttons {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 4px;
  border-top: 1px solid #efefef; }

.datepicker--button {
  color: #4EB5E6;
  cursor: pointer;
  border-radius: 4px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px; }

.datepicker--button:hover {
  color: #4a4a4a;
  background: #f0f0f0; }

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede; }

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5; }

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede; }

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6; }

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4; }

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc; }

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2); }

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none; }

/* -------------------------------------------------
    Timepicker
   ------------------------------------------------- */
.datepicker--time {
  border-top: 1px solid #efefef;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 4px;
  position: relative; }

.datepicker--time.-am-pm- .datepicker--time-sliders {
  -webkit-flex: 0 1 138px;
  -ms-flex: 0 1 138px;
  flex: 0 1 138px;
  max-width: 138px; }

.-only-timepicker- .datepicker--time {
  border-top: none; }

.datepicker--time-sliders {
  -webkit-flex: 0 1 153px;
  -ms-flex: 0 1 153px;
  flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px; }

.datepicker--time-label {
  display: none;
  font-size: 12px; }

.datepicker--time-current {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 14px;
  text-align: center;
  margin: 0 0 0 10px; }

.datepicker--time-current-colon {
  margin: 0 2px 3px;
  line-height: 1; }

.datepicker--time-current-hours,
.datepicker--time-current-minutes {
  line-height: 1;
  font-size: 19px;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  position: relative;
  z-index: 1; }

.datepicker--time-current-hours:after,
.datepicker--time-current-minutes:after {
  content: '';
  background: #f0f0f0;
  border-radius: 4px;
  position: absolute;
  left: -2px;
  top: -3px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  opacity: 0; }

.datepicker--time-current-hours.-focus-:after,
.datepicker--time-current-minutes.-focus-:after {
  opacity: 1; }

.datepicker--time-current-ampm {
  text-transform: uppercase;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: #9c9c9c;
  margin-left: 6px;
  font-size: 11px;
  margin-bottom: 1px; }

.datepicker--time-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 11px;
  height: 17px;
  background: linear-gradient(to right, #dedede, #dedede) left 50%/100% 1px no-repeat; }

.datepicker--time-row:first-child {
  margin-bottom: 4px; }

.datepicker--time-row input[type='range'] {
  background: none;
  cursor: pointer;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none; }

.datepicker--time-row input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; }

.datepicker--time-row input[type='range']::-ms-tooltip {
  display: none; }

.datepicker--time-row input[type='range']:hover::-webkit-slider-thumb {
  border-color: #b8b8b8; }

.datepicker--time-row input[type='range']:hover::-moz-range-thumb {
  border-color: #b8b8b8; }

.datepicker--time-row input[type='range']:hover::-ms-thumb {
  border-color: #b8b8b8; }

.datepicker--time-row input[type='range']:focus {
  outline: none; }

.datepicker--time-row input[type='range']:focus::-webkit-slider-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef; }

.datepicker--time-row input[type='range']:focus::-moz-range-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef; }

.datepicker--time-row input[type='range']:focus::-ms-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef; }

.datepicker--time-row input[type='range']::-webkit-slider-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background .2s; }

.datepicker--time-row input[type='range']::-moz-range-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background .2s; }

.datepicker--time-row input[type='range']::-ms-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background .2s; }

.datepicker--time-row input[type='range']::-webkit-slider-thumb {
  margin-top: -6px; }

.datepicker--time-row input[type='range']::-webkit-slider-runnable-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent; }

.datepicker--time-row input[type='range']::-moz-range-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent; }

.datepicker--time-row input[type='range']::-ms-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent; }

.datepicker--time-row input[type='range']::-ms-fill-lower {
  background: transparent; }

.datepicker--time-row input[type='range']::-ms-fill-upper {
  background: transparent; }

.datepicker--time-row span {
  padding: 0 12px; }

.datepicker--time-icon {
  color: #9c9c9c;
  border: 1px solid;
  border-radius: 50%;
  font-size: 16px;
  position: relative;
  margin: 0 5px -1px 0;
  width: 1em;
  height: 1em; }

.datepicker--time-icon:after, .datepicker--time-icon:before {
  content: '';
  background: currentColor;
  position: absolute; }

.datepicker--time-icon:after {
  height: .4em;
  width: 1px;
  left: calc(50% - 1px);
  top: calc(50% + 1px);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%); }

.datepicker--time-icon:before {
  width: .4em;
  height: 1px;
  top: calc(50% + 1px);
  left: calc(50% - 1px); }

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede; }

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5; }

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede; }

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6; }

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4; }

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc; }

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2); }

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none; }

/************************************************
* HEADER
*************************************************/
/************************************************
* General CONTENT Stylings
*************************************************/
.header-info {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  color: #808080;
  line-height: 2rem;
  font-size: 1.4rem;
  border-bottom: 1.5px solid #dedede; }
  .header-info .boxInfo {
    margin-top: 2rem;
    padding-left: 7rem;
    position: relative; }
    .header-info .boxInfo.phone {
      float: right;
      margin-right: 2rem; }
    .header-info .boxInfo.cert {
      float: right;
      margin-right: 2rem;
      padding-left: 0;
      width: 20%; }
      .header-info .boxInfo.cert p:first-child::before {
        content: url(/fileadmin/ege/img/ege-made-in-germany.svg);
        display: inline-block;
        height: 50px;
        width: 50px;
        float: left;
        margin-right: 1em; }
    .header-info .boxInfo i {
      font-size: 5rem;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      color: #028a5a;
      line-height: 1;
      display: block; }
    .header-info .boxInfo p {
      margin: 0; }
  .header-info a.logo {
    display: block;
    float: left;
    padding: 1rem 2rem; }
    .header-info a.logo img {
      width: 25rem; }

header {
  position: relative; }
  header:after {
    content: "";
    display: table;
    clear: both; }
  header .service-nav {
    float: right; }
  header a.login {
    font-size: 2.4rem;
    display: block;
    float: left;
    /*position: absolute;
		right: 2rem;
		bottom: .3rem;*/
    padding: .4rem;
    z-index: 10; }

html[lang="de"] header a.login::after {
  content: 'Händler-Login';
  font-size: 1.6rem;
  display: inline-block;
  padding: .3em 0 0 .2em;
  float: right; }

.header-nav {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0; }
  .header-nav nav {
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%; }
    .header-nav nav ul {
      padding-left: 0; }
  .header-nav .nav-icons {
    width: 32.20339%;
    float: right;
    margin-right: 0;
    text-align: right; }
    .header-nav .nav-icons a {
      display: inline-block;
      padding: 1.5rem;
      font-size: 2rem;
      color: #808080; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1100px) {
  header .service-nav {
    padding-right: 1rem; } }

@media (max-width: 1024px) {
  .header-info {
    margin-top: 1rem; }
    .header-info a.logo img {
      width: 18rem; } }

@media (max-width: 880px) {
  .header-info .boxInfo {
    margin-top: 1.5rem;
    padding-left: 5rem; }
    .header-info .boxInfo p, .header-info .boxInfo a {
      font-size: 1.5rem; }
    .header-info .boxInfo i {
      font-size: 4rem; }
  .header-info a.logo img {
    width: 20rem; } }

@media (max-width: 760px) {
  .header-info .boxInfo {
    margin-top: 1.5rem;
    padding-left: 3.7rem; }
    .header-info .boxInfo p, .header-info .boxInfo a {
      font-size: 1.3rem; }
    .header-info .boxInfo i {
      font-size: 3rem; }
  .header-info a.logo img {
    width: 17rem; } }

@media (max-width: 660px) {
  .header-info {
    margin-top: 0; }
    .header-info .boxInfo {
      padding-left: 1.7rem;
      margin-left: 2rem; }
      .header-info .boxInfo.phone {
        float: none;
        margin-right: 0;
        margin-bottom: 1rem; }
        .header-info .boxInfo.phone p {
          display: inline-block;
          margin-right: 1rem; }
      .header-info .boxInfo.cert {
        margin-left: 0; }
      .header-info .boxInfo p, .header-info .boxInfo a {
        font-size: 1.3rem; }
      .header-info .boxInfo i {
        font-size: 1.5rem; }
    .header-info a.logo img {
      width: 17rem; } }

@media (max-width: 420px) {
  .header-info .boxInfo.cert {
    display: none; }
  .header-info a.logo {
    float: none;
    text-align: center; }
    .header-info a.logo img {
      width: 17rem; } }

/************************************************
* navMain
*************************************************/
#navMain {
  float: left; }
  #navMain:after {
    content: "";
    display: table;
    clear: both; }

/* generelle Stylings für alle Ebenen */
ul.flexnav {
  background-color: white;
  /*padding:1rem;*/ }
  ul.flexnav:after {
    content: "";
    display: table;
    clear: both; }
  ul.flexnav a {
    color: #555;
    display: block;
    font-size: 1.7rem;
    transition: all .3s ease-in;
    text-transform: uppercase;
    text-align: left; }
    ul.flexnav a.act {
      color: #555; }

/* spezielle Stylings erste Ebene */
ul.flexnav > li > a {
  padding: 1rem 1.5rem 1rem 1.5rem;
  text-align: left; }
  @media (min-width: 1101px) {
    ul.flexnav > li > a:hover {
      color: #555;
      color: #028a5a;
      transition: all .6s; }
      ul.flexnav > li > a:hover:after, ul.flexnav > li > a:hover:focus {
        transform: scaleX(1); } }
  ul.flexnav > li > a.act {
    color: #028a5a; }
    ul.flexnav > li > a.act:after {
      border-bottom: solid 1px #028a5a;
      display: block;
      content: '';
      transform: scaleX(1);
      transition: transform 250ms ease-in-out; }
  ul.flexnav > li > a:after {
    display: block;
    content: '';
    border-bottom: solid 1px #028a5a;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out; }

/* spezielle Stylings zweite Ebene */
ul.flexnav li ul {
  background-color: #f7f7f7; }
  ul.flexnav li ul li a {
    padding: .8rem 1.5rem; }
    @media (min-width: 1101px) {
      ul.flexnav li ul li a:hover {
        background-color: #028a5a;
        color: white; } }
    ul.flexnav li ul li a.act, ul.flexnav li ul li a.current {
      background-color: #028a5a;
      color: white; }

/* spezielle Stylings dritte Ebene */
@media (min-width: 1101px) {
  ul.flexnav > li > ul > li > ul > li a:hover {
    background-color: #05a26b;
    color: white; } }

ul.flexnav > li > ul > li > ul > li a.act {
  background-color: #05a26b;
  color: white; }

.menu-button {
  display: none; }

/* spezielle Stylings vierte Ebene */
@media (min-width: 1101px) {
  ul.flexnav > li > ul > li > ul > li > ul > li a:hover {
    background-color: #10b67c; } }

ul.flexnav > li > ul > li > ul > li > ul > li a.act {
  background-color: #10b67c;
  color: white; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1024px) {
  ul.flexnav a {
    padding: 1rem 1.4rem; } }

@media (max-width: 1100px) {
  #navWrapper {
    float: none;
    clear: both;
    margin: 0; }
  #navMain {
    float: none;
    clear: both; }
  .menu-button {
    position: relative;
    display: block;
    cursor: pointer;
    float: left;
    padding-left: 4rem;
    margin-top: .8rem;
    font-size: 1.8rem; }
    .menu-button::before {
      content: '\f0c9';
      font-size: 2.4rem;
      position: absolute;
      display: block;
      left: 1rem;
      top: 50%;
      transform: translateY(-42%);
      color: #555; }
  ul.flexnav-show {
    margin: 0;
    display: block; }
  ul.flexnav > li > a:after {
    margin-top: 0; }
  ul.flexnav > li > a.act {
    margin-top: 0; }
  /* spezielle Stylings erste Ebene */
  ul.flexnav > li {
    margin-left: 0; }
  /* zweite Ebene */
  ul.flexnav > li > ul.flexnav-show > li {
    margin-left: 2rem; }
    ul.flexnav > li > ul.flexnav-show > li > a {
      padding: 1rem 1.4rem; } }

/************************************************
* lang Switch
*************************************************/
#language {
  float: left;
  margin-right: 1.3rem;
  margin-top: 1.3rem;
  /*
  position: absolute;
  right: 16rem;
  bottom: 1.2rem;
  z-index: 10;
  */
  text-transform: uppercase; }
  #language a,
  #language span {
    display: inline-block;
    padding: .5rem .3rem; }
  #language > span:not(.current),
  #language > a {
    opacity: .4; }
  #language a:hover {
    opacity: .8; }
  #language a.inactive {
    opacity: .2; }
  #language .current {
    opacity: 1; }
  #language .unavailable {
    display: none; }
  #language > span {
    float: left;
    width: 30px;
    height: 15px;
    background-size: cover;
    display: block;
    padding: 0;
    margin-right: .4em; }
  #language a {
    background-size: cover;
    display: block;
    width: inherit;
    height: inherit; }
  #language span.de {
    background-image: url(/fileadmin/ege/img/de.svg); }
  #language span.en {
    background-image: url(/fileadmin/ege/img/en.svg); }
  #language span.fr {
    background-image: url(/fileadmin/ege/img/fr.svg); }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1024px) {
  #language span {
    padding: .5rem 1rem; } }

@media (max-width: 360px) {
  #language {
    margin-right: 1rem; } }

@media (max-width: 340px) {
  #language {
    margin-right: .2rem; } }

/************************************************
* General CONTENT Stylings
*************************************************/
.headerImage {
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  position: relative; }
  .headerImage:after {
    content: " ";
    display: block;
    clear: both; }
  .headerImage img {
    display: block; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 2000px) {
  .headerImage {
    max-width: 92%; } }

@media (max-width: 1400px) {
  .headerImage {
    max-width: 95%; } }

@media (max-width: 1100px) {
  .headerImage {
    max-width: 100%; } }

/************************************************
* General CONTENT Stylings
*************************************************/
.breadcrumbWrapper {
  position: relative;
  width: 100%;
  background-color: #028a5a;
  padding: .2rem 0; }

#breadcrumb {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  width: 100%; }
  #breadcrumb:after {
    content: " ";
    display: block;
    clear: both; }
  #breadcrumb ol {
    padding-left: 0; }
  #breadcrumb li {
    display: inline;
    padding: 0.5rem; }
    #breadcrumb li:first-of-type {
      padding-left: 0; }
    #breadcrumb li a {
      color: white; }
      #breadcrumb li a:after {
        content: " /";
        padding-left: 0.5rem;
        padding-right: 0.5rem; }
    #breadcrumb li > span {
      color: #fff; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 2000px) {
  #breadcrumb {
    max-width: 91%; } }

@media (max-width: 1650px) {
  #breadcrumb {
    max-width: 97.5%; } }

@media (max-width: 1100px) {
  #breadcrumb {
    max-width: 95%; } }

/************************************************
* Slider (Text / Media - Layout 10)
*************************************************/
#slider {
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem; }
  #slider:after {
    content: " ";
    display: block;
    clear: both; }
  #slider .slide {
    position: relative;
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    #slider .slide .background {
      background-position: center;
      background-size: cover;
      padding-top: 33.3%; }
    #slider .slide .slider-details {
      position: absolute;
      right: 6rem;
      bottom: 6rem;
      padding: 2rem;
      max-width: 80rem;
      background-color: rgba(255, 255, 255, 0.8); }
      #slider .slide .slider-details:after {
        content: "";
        display: table;
        clear: both; }
      #slider .slide .slider-details .title {
        color: #555;
        font-weight: 600;
        text-align: left;
        font-size: 2.9rem;
        display: block;
        margin-bottom: 1rem; }
      #slider .slide .slider-details .text {
        color: #555;
        font-weight: 400;
        font-size: 1.9rem;
        display: block;
        margin-bottom: 1rem; }
      #slider .slide .slider-details a {
        float: right;
        margin-top: 1rem; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 2000px) {
  #slider {
    max-width: 92%; } }

@media (max-width: 1500px) {
  #slider .slide .slider-details {
    max-width: 60rem; }
    #slider .slide .slider-details .title {
      font-size: 2.5rem; }
    #slider .slide .slider-details .text {
      font-size: 1.6rem; } }

@media (max-width: 1400px) {
  #slider {
    max-width: 95%; } }

@media (max-width: 1100px) {
  #slider {
    max-width: 100%; }
    #slider .slide .background {
      padding-top: 40%; }
    #slider .slide .slider-details .title {
      font-size: 2.2rem; } }

@media (max-width: 1024px) {
  #slider .slide .slider-details {
    right: 3rem;
    bottom: 3rem;
    padding-left: 3rem;
    max-width: 60rem; }
    #slider .slide .slider-details .title {
      margin-bottom: 2rem; } }

@media (max-width: 768px) {
  #slider .slide .background {
    padding-top: 50%; }
  #slider .slide .slider-details {
    max-width: 50rem; } }

@media (max-width: 700px) {
  #slider .slide .slider-details {
    position: relative;
    display: block;
    max-width: 100%;
    bottom: auto;
    right: auto;
    background-color: #f7f7f7; } }

@media (max-width: 400px) {
  #slider .slide .slider-details {
    padding: 2rem; }
    #slider .slide .slider-details .title {
      font-size: 2rem; } }

/************************************************
* CONTENT
*************************************************/
/************************************************
* Featureboxen 2-spaltig (ohne Bild) (Cluster Layout 30)
*************************************************/
.boxesFeature2Col {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem; }
  .boxesFeature2Col > h1, .boxesFeature2Col > h2 {
    color: #555;
    text-align: center;
    margin-bottom: 3rem; }
  .boxesFeature2Col .boxes {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .boxesFeature2Col .feature {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%;
    background-color: #e8e8e8;
    padding: 5rem 3rem;
    margin-bottom: 5rem; }
    .boxesFeature2Col .feature h2, .boxesFeature2Col .feature h3 {
      color: #555;
      margin-top: 0; }
    .boxesFeature2Col .feature p {
      color: #808080; }
    .boxesFeature2Col .feature span {
      color: #028a5a; }
    .boxesFeature2Col .feature a {
      float: right;
      margin-top: 1rem; }
    .boxesFeature2Col .feature:nth-of-type(2n) {
      width: 49.15254%;
      float: right;
      margin-right: 0; }
    .boxesFeature2Col .feature ul {
      padding-left: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 850px) {
  .boxesFeature2Col .feature {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .boxesFeature2Col .feature:nth-of-type(2n) {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }

@media (max-width: 600px) {
  .boxesFeature2Col .feature {
    padding: 2.5rem; } }

/************************************************
* Featureboxen 3-spaltig grau (quadratisches Icon) (Cluster Layout 50)
*************************************************/
.boxesFeature3ColCircleImage {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem; }
  .boxesFeature3ColCircleImage .boxes {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .boxesFeature3ColCircleImage .feature {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%;
    display: block;
    padding: 4rem 3rem;
    text-align: center;
    background-color: #e8e8e8;
    color: #808080;
    transition: color ease-in-out .5s, background-color ease-in-out .5s;
    margin-bottom: 5rem; }
    .boxesFeature3ColCircleImage .feature:nth-of-type(3n) {
      width: 32.20339%;
      float: right;
      margin-right: 0; }
    .boxesFeature3ColCircleImage .feature div {
      width: 9rem;
      height: 9rem;
      display: inline-block;
      margin: 5px;
      transition: all .3s ease; }
      .boxesFeature3ColCircleImage .feature div img {
        display: block; }
    .boxesFeature3ColCircleImage .feature h2, .boxesFeature3ColCircleImage .feature h3 {
      transition: color ease-in-out .5s; }
    .boxesFeature3ColCircleImage .feature p {
      margin-bottom: 2rem;
      transition: color ease-in-out .5s; }
    .boxesFeature3ColCircleImage .feature:hover {
      background-color: #028a5a; }
      .boxesFeature3ColCircleImage .feature:hover button.more-btn {
        background-color: white;
        color: #028a5a; }
      .boxesFeature3ColCircleImage .feature:hover h2,
      .boxesFeature3ColCircleImage .feature:hover h3,
      .boxesFeature3ColCircleImage .feature:hover p {
        color: white; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1100px) {
  .boxesFeature3ColCircleImage .feature {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%;
    margin-bottom: 2rem; }
  .boxesFeature3ColCircleImage .feature:nth-of-type(2n) {
    width: 49.15254%;
    float: right;
    margin-right: 0; }
  .boxesFeature3ColCircleImage .feature:nth-of-type(3n) {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; } }

@media (max-width: 1024px) {
  .boxesFeature3ColCircleImage .feature:hover {
    color: #808080;
    background-color: #e8e8e8; }
    .boxesFeature3ColCircleImage .feature:hover:hover button.more-btn {
      background-color: #028a5a;
      color: white; }
    .boxesFeature3ColCircleImage .feature:hover h2, .boxesFeature3ColCircleImage .feature:hover h3 {
      color: #555; }
    .boxesFeature3ColCircleImage .feature:hover p {
      color: #808080; } }

@media (max-width: 600px) {
  .boxesFeature3ColCircleImage .feature {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .boxesFeature3ColCircleImage .feature:nth-of-type(2n),
  .boxesFeature3ColCircleImage .feature:nth-of-type(3n) {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; } }

/************************************************
* Featureboxen 3-spaltig (quadratisches Bild) (Cluster Layout 40)
*************************************************/
.boxesFeature3ColSquareImage {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem; }
  .boxesFeature3ColSquareImage .featureHeadline {
    text-align: center;
    position: relative; }
    .boxesFeature3ColSquareImage .featureHeadline h2, .boxesFeature3ColSquareImage .featureHeadline h3 {
      display: inline-block;
      padding: 10px;
      color: #808080;
      background-color: white; }
      .boxesFeature3ColSquareImage .featureHeadline h2:before, .boxesFeature3ColSquareImage .featureHeadline h3:before {
        content: "";
        width: 100%;
        border-top: 1px solid #cecece;
        height: 1px;
        left: 0;
        top: 5.1rem;
        display: block;
        position: absolute;
        background: #fff;
        z-index: -1; }
    .boxesFeature3ColSquareImage .featureHeadline h1::before,
    .boxesFeature3ColSquareImage .featureHeadline h1::after {
      content: none; }
  .boxesFeature3ColSquareImage .boxes {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .boxesFeature3ColSquareImage .boxes .feature {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%;
      display: block;
      margin-bottom: 5rem; }
      .boxesFeature3ColSquareImage .boxes .feature:nth-child(3n) {
        width: 32.20339%;
        float: right;
        margin-right: 0; }
      .boxesFeature3ColSquareImage .boxes .feature img {
        margin-bottom: 1rem;
        transition: all .3s ease-in-out; }
      .boxesFeature3ColSquareImage .boxes .feature span {
        font-size: 1.8rem;
        font-weight: 600;
        color: gray;
        transition: all .3s ease-in-out; }
      .boxesFeature3ColSquareImage .boxes .feature span + p {
        margin-top: .5rem; }
    .boxesFeature3ColSquareImage .boxes a.feature:hover img {
      opacity: .7; }
    .boxesFeature3ColSquareImage .boxes a.feature:hover span {
      color: #028a5a; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1024px) {
  .boxesFeature3ColSquareImage .boxes a.feature:hover img {
    opacity: 1; }
  .boxesFeature3ColSquareImage .boxes a.feature:hover span {
    color: gray; } }

@media (max-width: 900px) {
  .boxesFeature3ColSquareImage .boxes .feature,
  .boxesFeature3ColSquareImage .boxes .feature:nth-child(3n) {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
  .boxesFeature3ColSquareImage .boxes .feature:nth-child(2n) {
    width: 49.15254%;
    float: right;
    margin-right: 0; } }

@media (max-width: 600px) {
  .boxesFeature3ColSquareImage .boxes .feature,
  .boxesFeature3ColSquareImage .boxes .feature:nth-child(2n),
  .boxesFeature3ColSquareImage .boxes .feature:nth-child(3n) {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1.5rem; } }

/************************************************
* Featureboxen 4-spaltig (kreisförmiges Bild) (Cluster Layout 60)
*************************************************/
.boxesFeature4ColCircleImage {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem; }
  .boxesFeature4ColCircleImage .feature {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%;
    background-color: #e8e8e8;
    padding: 4rem 2rem;
    text-align: center;
    margin-bottom: 5rem; }
    .boxesFeature4ColCircleImage .feature div {
      background-color: white;
      border-radius: 50%;
      width: 12rem;
      height: 12rem;
      display: inline-block;
      margin: 5px;
      transition: all .3s ease; }
      .boxesFeature4ColCircleImage .feature div img {
        width: 12rem;
        height: 12rem;
        border-radius: 50%; }
    .boxesFeature4ColCircleImage .feature ul {
      margin: 0 0 2rem;
      padding: 0; }
      .boxesFeature4ColCircleImage .feature ul li {
        margin-bottom: .5rem; }
    .boxesFeature4ColCircleImage .feature p {
      margin-bottom: 2rem; }
  .boxesFeature4ColCircleImage .feature:nth-of-type(4n) {
    width: 23.72881%;
    float: right;
    margin-right: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1150px) {
  .boxesFeature4ColCircleImage .feature {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%;
    margin-bottom: 1.5rem; }
  .boxesFeature4ColCircleImage .feature:nth-of-type(2n) {
    width: 49.15254%;
    float: right;
    margin-right: 0; } }

@media (max-width: 600px) {
  .boxesFeature4ColCircleImage .feature {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1.5rem; }
  .boxesFeature4ColCircleImage .feature:nth-of-type(2n) {
    width: 100%;
    float: right;
    margin-right: 0; } }

/************************************************
* Featureboxen 4-spaltig (Referenzen) (Cluster Layout 70)
*************************************************/
.filterSelector a {
  color: #999999;
  border: 1px solid #cccccc;
  display: inline-block;
  margin: 1.3em .2em;
  padding: .3em .6em;
  font-size: 1.25em;
  font-weight: 400; }
  .filterSelector a:hover {
    color: #666666; }
  .filterSelector a.active {
    color: #fff;
    border-color: #028a5a;
    background-color: #028a5a; }

main .filterSelector a[href^="#"]:after {
  content: none; }

.boxesFeature4ColReferences {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem; }
  .boxesFeature4ColReferences .boxes {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .boxesFeature4ColReferences .reference {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%;
    background-color: #e8e8e8;
    position: relative;
    margin-bottom: 1.69492%;
    display: block; }
    .boxesFeature4ColReferences .reference:nth-of-type(4n) {
      float: right;
      margin-right: 0; }
    .boxesFeature4ColReferences .reference .background {
      background-size: cover;
      background-position: center;
      padding-top: 100%;
      transition: opacity 0.32s ease; }
    .boxesFeature4ColReferences .reference p {
      position: absolute;
      visibility: hidden;
      bottom: 5rem;
      z-index: 1;
      width: 100%;
      display: block;
      padding: 0 2rem;
      margin: 0;
      opacity: 0;
      transition: all 0.32s ease; }
    .boxesFeature4ColReferences .reference img {
      opacity: 1;
      transition: all 0.32s ease;
      width: 100%;
      height: auto;
      display: block; }
    .boxesFeature4ColReferences .reference:hover .background {
      opacity: 0; }
    .boxesFeature4ColReferences .reference:hover p {
      opacity: 1;
      visibility: visible;
      z-index: 13;
      bottom: 8rem; }
    .boxesFeature4ColReferences .reference:hover .boxText {
      background-color: #e8e8e8; }
    .boxesFeature4ColReferences .reference:hover span {
      color: #555; }
    .boxesFeature4ColReferences .reference:hover img {
      opacity: 0; }
  .boxesFeature4ColReferences .boxText {
    padding: 1rem 8rem 1rem 2rem;
    position: absolute;
    bottom: -1px;
    width: 100%;
    background-color: #028a5a;
    transition: background-color 0.5s ease; }
    .boxesFeature4ColReferences .boxText span {
      color: white;
      text-transform: uppercase;
      margin: 0;
      transition: color 0.5s ease; }
  .boxesFeature4ColReferences .arrow {
    position: absolute;
    background-color: #0da871;
    top: 0;
    right: 0;
    bottom: 0;
    width: 6rem; }
    .boxesFeature4ColReferences .arrow::after {
      content: '\e810';
      font-size: 1.8rem;
      position: absolute;
      color: white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 1024px) {
  .filterSelector {
    float: right;
    position: relative;
    margin-top: 2.5em;
    z-index: 10; } }

@media (max-width: 1024px) {
  .boxesFeature4ColReferences .reference {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .boxesFeature4ColReferences .reference .background {
      opacity: .1; }
    .boxesFeature4ColReferences .reference p {
      visibility: visible;
      opacity: 1;
      display: block;
      padding: 2rem; }
    .boxesFeature4ColReferences .reference:nth-of-type(2n) {
      float: right;
      margin-right: 0; }
    .boxesFeature4ColReferences .reference:hover h4 {
      color: white; }
    .boxesFeature4ColReferences .reference:hover span {
      color: white; }
    .boxesFeature4ColReferences .reference:hover img {
      opacity: .1; }
    .boxesFeature4ColReferences .reference:hover p {
      bottom: 5rem; }
    .boxesFeature4ColReferences .reference:hover .boxText {
      background-color: #028a5a; }
    .boxesFeature4ColReferences .reference:hover .background {
      opacity: .1; } }

@media (max-width: 600px) {
  .boxesFeature4ColReferences .reference {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 3.38983%; }
    .boxesFeature4ColReferences .reference:last-child {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
    .boxesFeature4ColReferences .reference:nth-of-type(n) {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }

/************************************************
* Google Maps 3-spaltig (Cluster Layout 120)
*************************************************/
.boxesGoogleMaps {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem; }
  .boxesGoogleMaps .boxes {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .boxesGoogleMaps .box {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%;
    background-color: #e8e8e8;
    padding: 4rem;
    text-align: center; }
    .boxesGoogleMaps .box:nth-of-type(3n) {
      width: 32.20339%;
      float: right;
      margin-right: 0; }
    .boxesGoogleMaps .box figure {
      margin: 0; }
    .boxesGoogleMaps .box .embed-container {
      position: relative;
      padding-bottom: 70%;
      height: 0;
      overflow: hidden;
      width: 100%;
      height: auto;
      margin-top: 4rem; }
    .boxesGoogleMaps .box .embed-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1000px) {
  .boxesGoogleMaps .box {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%;
    margin-bottom: 3.38983%; }
    .boxesGoogleMaps .box:nth-of-type(2n) {
      width: 49.15254%;
      float: right;
      margin-right: 0; }
    .boxesGoogleMaps .box:nth-of-type(3n) {
      width: 49.15254%;
      float: left;
      margin-right: 1.69492%; } }

@media (max-width: 750px) {
  .boxesGoogleMaps .box {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .boxesGoogleMaps .box:nth-of-type(2n) {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
    .boxesGoogleMaps .box:nth-of-type(3n) {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }

/************************************************
* Text 2-spaltig (Cluster Layout 80)
*************************************************/
.boxesText2Col {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem; }
  .boxesText2Col .boxes {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .boxesText2Col .box {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .boxesText2Col .box:nth-of-type(2n) {
      width: 49.15254%;
      float: right;
      margin-right: 0; }
    .boxesText2Col .box ul {
      padding-left: 0; }

.boxesText3Col {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem; }
  .boxesText3Col .boxes {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  @media screen and (min-width: 850px) {
    .boxesText3Col .box {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%; }
      .boxesText3Col .box:nth-of-type(2n) {
        width: 32.20339%;
        float: right;
        margin-right: 0; } }
  .boxesText3Col .box ul {
    padding-left: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 750px) {
  .boxesText2Col .box {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .boxesText2Col .box:nth-of-type(2n) {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }

/************************************************
* Kontaktinfos 3-spaltig mit Icon (Cluster Layout 90)
*************************************************/
.contactInfo3Col {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem; }
  .contactInfo3Col .boxes {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .contactInfo3Col > h1, .contactInfo3Col > h2, .contactInfo3Col > h3 {
    text-align: center;
    margin: 0 0 3rem 0; }
  .contactInfo3Col .box {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%;
    max-width: 31.5%;
    position: relative; }
    .contactInfo3Col .box h1, .contactInfo3Col .box h2, .contactInfo3Col .box h3 {
      color: #808080;
      font-size: 1.5rem; }
    .contactInfo3Col .box figure {
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 6rem;
      width: 6rem;
      left: 1rem; }
      .contactInfo3Col .box figure img {
        position: absolute;
        display: block; }
    .contactInfo3Col .box .content {
      padding-left: 10rem; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1000px) {
  .contactInfo3Col .box {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .contactInfo3Col .box:nth-child(3) {
      width: 49.15254%;
      float: left;
      margin-right: 1.69492%; }
    .contactInfo3Col .box:nth-child(2) {
      width: 49.15254%;
      float: right;
      margin-right: 0; } }

@media (max-width: 600px) {
  .contactInfo3Col {
    margin: 5rem 0; }
    .contactInfo3Col .box {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
      .contactInfo3Col .box:nth-child(2), .contactInfo3Col .box:nth-child(3) {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0; } }

/************************************************
* Kontaktinfos 4-spaltig (Cluster Layout 100)
*************************************************/
.contactInfo4Col {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 7rem;
  background-color: #e8e8e8;
  padding: 3rem; }
  .contactInfo4Col .boxes {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .contactInfo4Col h1, .contactInfo4Col h2, .contactInfo4Col h3 {
    text-align: center; }
  .contactInfo4Col .box {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%;
    background-color: white;
    padding: 2rem;
    margin-bottom: 2rem; }
    .contactInfo4Col .box:nth-of-type(4n) {
      width: 23.72881%;
      float: right;
      margin-right: 0; }
    .contactInfo4Col .box p {
      font-weight: 500;
      color: #555;
      text-align: center; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1000px) {
  .contactInfo4Col .box {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .contactInfo4Col .box:nth-of-type(2n) {
      float: right;
      margin-right: 0; }
    .contactInfo4Col .box:nth-of-type(4n) {
      width: 49.15254%;
      float: right;
      margin-right: 0; } }

@media (max-width: 600px) {
  .contactInfo4Col {
    margin: 5rem 0; }
    .contactInfo4Col .box {
      padding: 2.5rem; } }

@media (max-width: 550px) {
  .contactInfo4Col .box {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .contactInfo4Col .box:nth-of-type(2n) {
      clear: both;
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
    .contactInfo4Col .box:nth-of-type(4n) {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }

/************************************************
* Iconauflistung 2-spaltig (Cluster Layout 80)
*************************************************/
.iconList2Col {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem;
  background-color: #e8e8e8;
  padding: 2rem 4rem; }
  .iconList2Col .boxes {
    margin-left: 16.94915%;
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%;
    margin-right: 16.94915%; }
  .iconList2Col h1, .iconList2Col h2, .iconList2Col h3 {
    margin-left: 16.94915%;
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%;
    margin-right: 16.94915%; }
  .iconList2Col .box {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%;
    margin-bottom: 2rem;
    min-height: 8rem; }
    .iconList2Col .box:nth-of-type(2n) {
      width: 49.15254%;
      float: right;
      margin-right: 0; }
    .iconList2Col .box figure {
      position: absolute;
      background-color: #028a5a;
      border-radius: 50%;
      width: 7rem;
      height: 7rem;
      margin: 5px;
      transition: all .3s ease;
      padding: 1rem; }
    .iconList2Col .box img {
      width: 55%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute; }
    .iconList2Col .box .text {
      padding-left: 9rem; }

.iconList2Col.noColor .box figure {
  background-color: inherit;
  padding: 0; }

.iconList2Col.noColor .box img {
  width: 100%;
  transform: inherit;
  top: inherit;
  left: inherit; }

/*****************
* RESPONSIVE
******************/
@media (max-width: 1100px) {
  .iconList2Col h1, .iconList2Col h2, .iconList2Col h3 {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    margin-left: 0%;
    margin-right: 0%; }
  .iconList2Col .boxes {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 2rem; }
  .iconList2Col .box .figure {
    width: 6rem;
    height: 6rem; } }

@media (max-width: 750px) {
  .iconList2Col {
    padding: 2rem; }
    .iconList2Col .boxes {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 2rem; }
    .iconList2Col .box {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
      .iconList2Col .box:nth-of-type(2n) {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0; } }

/************************************************
* Bild + Text 2-spaltig (kreisförmiges Bild) (Cluster Layout 10)
*************************************************/
.imageText2ColCircleImage {
  margin-bottom: 3rem;
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0; }
  .imageText2ColCircleImage div {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .imageText2ColCircleImage div figure {
      margin: 0;
      width: 13.7931%;
      float: left;
      margin-right: 3.44828%; }
    .imageText2ColCircleImage div .content {
      width: 82.75862%;
      float: right;
      margin-right: 0;
      padding: 0; }
      .imageText2ColCircleImage div .content *:first-child {
        margin-top: 0; }
    .imageText2ColCircleImage div ul {
      margin: 0;
      padding: 0; }
  .imageText2ColCircleImage div:nth-of-type(2n) {
    width: 49.15254%;
    float: right;
    margin-right: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 750px) {
  .imageText2ColCircleImage div {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .imageText2ColCircleImage div:nth-of-type(2n) {
    width: 100%;
    float: right;
    margin-right: 0; } }

/************************************************
* Bild + Text untereinander 2-spaltig (Fließtextbreite) (Cluster Layout 20)
*************************************************/
.imageText2ColImageBelow {
  width: 57.62712%;
  float: left;
  margin-right: 1.69492%;
  margin-bottom: 3rem; }
  .imageText2ColImageBelow .box {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%;
    margin-bottom: 3rem; }
    .imageText2ColImageBelow .box figure {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin: 0 0 1rem 0; }
    .imageText2ColImageBelow .box .title {
      font-size: 1.6rem;
      font-weight: bold; }
    .imageText2ColImageBelow .box:nth-of-type(2n) {
      width: 49.15254%;
      float: right;
      margin-right: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1350px) {
  .imageText2ColImageBelow {
    width: 83.05085%;
    float: left;
    margin-right: 1.69492%; } }

@media (max-width: 1024px) {
  .imageText2ColImageBelow {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; } }

@media (max-width: 550px) {
  .imageText2ColImageBelow .box {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .imageText2ColImageBelow .box:nth-of-type(2n) {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }

/************************************************
* Bild + Text untereinander 4-spaltig (Cluster Layout 20)
*************************************************/
.imageText4ColImageBelow {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem; }
  .imageText4ColImageBelow .box {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%;
    margin-bottom: 3rem; }
    .imageText4ColImageBelow .box figure {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin: 0 0 1rem 0; }
    .imageText4ColImageBelow .box .title {
      font-size: 1.6rem;
      font-weight: bold; }
    .imageText4ColImageBelow .box:nth-of-type(4n) {
      width: 23.72881%;
      float: right;
      margin-right: 0; }
    .imageText4ColImageBelow .box ul {
      padding-left: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1150px) {
  .imageText4ColImageBelow .box {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .imageText4ColImageBelow .box:nth-of-type(2n), .imageText4ColImageBelow .box:nth-of-type(4n) {
      width: 49.15254%;
      float: right;
      margin-right: 0; } }

@media (max-width: 550px) {
  .imageText4ColImageBelow .box {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .imageText4ColImageBelow .box:nth-of-type(2n), .imageText4ColImageBelow .box:nth-of-type(4n) {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }

/************************************************
* Referenztext 2-spaltig (Cluster Layout 110)
*************************************************/
.referenceText2Col {
  width: 74.57627%;
  float: left;
  margin-right: 1.69492%;
  margin-bottom: 3rem; }
  .referenceText2Col .boxes {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .referenceText2Col .box {
    width: 65.90909%;
    float: left;
    margin-right: 2.27273%; }
    .referenceText2Col .box:nth-of-type(2n) {
      width: 31.81818%;
      float: right;
      margin-right: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1350px) {
  .referenceText2Col {
    width: 83.05085%;
    float: left;
    margin-right: 1.69492%; }
    .referenceText2Col .box {
      width: 69.38776%;
      float: left;
      margin-right: 2.04082%; }
      .referenceText2Col .box:nth-of-type(2n) {
        width: 28.57143%;
        float: right;
        margin-right: 0; } }

@media (max-width: 1024px) {
  .referenceText2Col {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .referenceText2Col .box {
      width: 66.10169%;
      float: left;
      margin-right: 1.69492%; }
      .referenceText2Col .box:nth-of-type(2n) {
        width: 32.20339%;
        float: right;
        margin-right: 0; } }

@media (max-width: 650px) {
  .referenceText2Col .box {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .referenceText2Col .box:nth-of-type(2n) {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }

/************************************************
* Galerie (Text / Media - Layout 170)
*************************************************/
.gallery {
  width: 57.62712%;
  float: left;
  margin-right: 1.69492%;
  margin-bottom: 3rem; }
  .gallery .boxes {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .gallery .box {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%;
    margin-bottom: 2rem; }
    .gallery .box:nth-of-type(4n) {
      width: 23.72881%;
      float: right;
      margin-right: 0; }
  .gallery figure {
    margin: 0 0 .5rem 0; }
    .gallery figure img {
      display: block; }
    .gallery figure figcaption {
      color: gray;
      font-size: 1.6rem; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1350px) {
  .gallery {
    width: 83.05085%;
    float: left;
    margin-right: 1.69492%; } }

@media (max-width: 1024px) {
  .gallery {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; } }

@media (max-width: 500px) {
  .gallery .box {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .gallery .box:nth-of-type(2n) {
      width: 49.15254%;
      float: right;
      margin-right: 0; } }

/************************************************
* Downloadbox (Text / Media - Layout 240)
*************************************************/
.boxesDownload {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem;
  background-color: #e8e8e8;
  padding: 1rem 4rem; }
  .boxesDownload .boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .boxesDownload .boxes::after {
      content: "";
      flex: auto; }
  .boxesDownload .box {
    flex: 0 1 23.4%;
    margin: .8%;
    min-width: 300px;
    overflow: auto;
    display: block;
    background-color: white;
    padding: 1rem 2rem;
    transition: color .4s ease, background-color .4s ease;
    font-size: .9em; }
    .boxesDownload .box img {
      border: 1px solid #ddd; }
    .boxesDownload .box:hover {
      background-color: #028a5a; }
      .boxesDownload .box:hover p {
        color: white; }
      .boxesDownload .box:hover i {
        color: white; }
  .boxesDownload h2, .boxesDownload h3 {
    text-align: center;
    margin: 1.5rem 0; }
    .boxesDownload h2.closed, .boxesDownload h2.open, .boxesDownload h3.closed, .boxesDownload h3.open {
      cursor: pointer; }
  .boxesDownload i {
    font-size: 200%;
    float: left;
    display: inline-block;
    width: 20%;
    padding-right: 4%; }
  .boxesDownload p {
    margin: .2rem 0;
    float: left;
    display: inline-block;
    width: 76%;
    line-height: 1.8rem; }

/************************************************
 * RESPONSIVE
 ************************************************/
@media (max-width: 1024px) {
  .boxesDownload .box:hover {
    background-color: white; }
    .boxesDownload .box:hover p {
      color: #808080; }
    .boxesDownload .box:hover i {
      color: #028a5a; } }

@media (max-width: 900px) {
  .boxesDownload .box {
    margin-bottom: 1rem; } }

@media (max-width: 600px) {
  .boxesDownload {
    padding: 2.5rem; }
    .boxesDownload .box {
      margin-bottom: 1rem; } }

/************************************************
* Configurator (Text / Media - Layout 220)
*************************************************/
.configurator {
  width: 1000px;
  float: left;
  margin-right: 1.69492%;
  margin-bottom: 3rem; }
  .configurator .embed-container {
    position: relative;
    padding-bottom: 70%;
    height: 0;
    overflow: hidden;
    width: 100%;
    height: auto; }
  .configurator .embed-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1100px) {
  .configurator {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; } }

/************************************************
* Überschrift zentriert (Text / Media - Layout 160)
*************************************************/
.headlineCentered {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  text-align: center; }
  .headlineCentered h1::after {
    content: none; }

/************************************************
* RESPONSIVE
*************************************************/
/************************************************
* Überschrift linksbündig (Text / Media - Layout 150)
*************************************************/
.headlineLeft {
  width: 57.62712%;
  float: left;
  margin-right: 1.69492%;
  margin-bottom: 3rem; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1350px) {
  .headlineLeft {
    width: 83.05085%;
    float: left;
    margin-right: 1.69492%; } }

@media (max-width: 1024px) {
  .headlineLeft {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; } }

/************************************************
* Textbox grau (Text / Media - Layout 140)
*************************************************/
.highlightBox {
  width: 57.62712%;
  float: left;
  margin-right: 1.69492%;
  margin-bottom: 3rem;
  background-color: #e8e8e8;
  padding: 4rem; }
  .highlightBox h1,
  .highlightBox h2,
  .highlightBox h3 {
    margin-top: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1350px) {
  .highlightBox {
    width: 83.05085%;
    float: left;
    margin-right: 1.69492%; } }

@media (max-width: 1024px) {
  .highlightBox {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; } }

@media (max-width: 600px) {
  .highlightBox {
    margin: 3rem 0;
    padding: 2.5rem; } }

/************************************************
* Bild volle Breite (Text / Media - Layout 80)
*************************************************/
.imageFull {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  /*padding: 2rem;*/
  margin-bottom: 5rem; }

/************************************************
* Bild + Text nebeneinander (Text / Media - Layout 100)
*************************************************/
.imageText {
  width: 57.62712%;
  float: left;
  margin-right: 1.69492%;
  margin-bottom: 3rem; }
  .imageText figure {
    margin: 0;
    width: 40.67797%;
    float: left;
    margin-right: 1.69492%; }
  .imageText .text {
    width: 57.62712%;
    float: right;
    margin-right: 0; }
    .imageText .text h2,
    .imageText .text h3 {
      margin-top: 0; }
    .imageText .text p:first-of-type {
      margin-top: 0; }
    .imageText .text ul {
      padding-left: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1350px) {
  .imageText {
    width: 83.05085%;
    float: left;
    margin-right: 1.69492%; } }

@media (max-width: 1024px) {
  .imageText {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; } }

@media (max-width: 600px) {
  .imageText figure {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2rem; }
  .imageText .text {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; } }

/************************************************
* Bild + Text nebeneinander (Text / Media - Layout 110)
*************************************************/
.imageTextFull {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem; }
  .imageTextFull figure {
    margin: 0;
    width: 40.67797%;
    float: left;
    margin-right: 1.69492%; }
  .imageTextFull .text {
    width: 57.62712%;
    float: right;
    margin-right: 0; }
    .imageTextFull .text h2,
    .imageTextFull .text h3 {
      margin-top: 0; }
    .imageTextFull .text p:first-of-type {
      margin-top: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 650px) {
  .imageTextFull figure {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
  .imageTextFull .text {
    width: 66.10169%;
    float: right;
    margin-right: 0; } }

@media (max-width: 650px) {
  .imageTextFull figure {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2rem; }
  .imageTextFull .text {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; } }

/************************************************
* Bild Fließtextbreite (Text / Media - Layout 90)
*************************************************/
.imageTextWidth {
  width: 57.62712%;
  float: left;
  margin-right: 1.69492%;
  margin-bottom: 3rem; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1350px) {
  .imageTextWidth {
    width: 83.05085%;
    float: left;
    margin-right: 1.69492%; } }

@media (max-width: 1024px) {
  .imageTextWidth {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; } }

@media (max-width: 600px) {
  .imageTextWidth {
    margin: 2rem 0; } }

/************************************************
* Logoauflistung (Text / Media - Layout 180)
*************************************************/
.logoList {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem;
  text-align: center;
  border-top: 1px solid #cccccc;
  padding-bottom: 2rem; }
  .logoList .logos {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .logoList h1, .logoList h2, .logoList h3 {
    text-transform: uppercase; }
  .logoList .logo {
    display: inline-block;
    margin: 0 2.5%; }

/*****************
* RESPONSIVE 
******************/
/************************************************
* Text Standard (Text / Media - Layout Default)
*************************************************/
.textStandard {
  width: 57.62712%;
  float: left;
  margin-right: 1.69492%;
  margin-bottom: 3rem; }
  .textStandard ul {
    padding-left: 0; }
  .textStandard li {
    text-align: left;
    line-height: 1.6;
    padding-left: 2rem;
    margin-bottom: 1.5rem;
    position: relative; }
    .textStandard li::before {
      content: '\e80f';
      color: #028a5a;
      position: absolute;
      left: 0;
      top: .3rem;
      font-size: 1.2rem; }
  .textStandard *:last-child {
    margin-bottom: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1400px) {
  .textStandard {
    width: 74.57627%;
    float: left;
    margin-right: 1.69492%; } }

@media (max-width: 1100px) {
  .textStandard {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; } }

/************************************************
* Bild (Referenz) (Text / Media - Layout 70)
*************************************************/
.referenceGallery {
  width: 74.57627%;
  float: left;
  margin-right: 1.69492%;
  text-align: center;
  margin-bottom: 3rem; }
  .referenceGallery img {
    display: block; }
  .referenceGallery .referenceThumbnailWrapper {
    margin-top: 1rem; }
    .referenceGallery .referenceThumbnailWrapper .swiper-slide {
      width: 20%;
      height: 100%;
      opacity: 0.6; }
      .referenceGallery .referenceThumbnailWrapper .swiper-slide.swiper-slide-active {
        opacity: 1; }
  .referenceGallery .swiper-button-next, .referenceGallery .swiper-button-prev {
    background-color: #808080;
    opacity: 0.5;
    margin-top: auto;
    width: 50px;
    height: 60px;
    transform: translateY(-50%); }
  .referenceGallery .swiper-button-disabled {
    display: none; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1350px) {
  .referenceGallery {
    width: 83.05085%;
    float: left;
    margin-right: 1.69492%; } }

@media (max-width: 1024px) {
  .referenceGallery {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; } }

/************************************************
* Video (Text / Media - Layout 220)
*************************************************/
.video figure {
  width: 40.67797%;
  float: left;
  margin-right: 1.69492%;
  margin-bottom: 3rem;
  position: relative;
  padding-bottom: 22.9%;
  height: 0;
  overflow: hidden;
  height: auto; }
  .video figure iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1350px) {
  .video {
    width: 83.05085%;
    float: left;
    margin-right: 1.69492%; } }

@media (max-width: 1024px) {
  .video {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; } }

.netlegend {
  margin-bottom: 4em; }
  .netlegend dl > a {
    position: relative;
    transform: translateY(-80px);
    display: block; }
  .netlegend dt {
    border-top: 1px solid #cecece;
    padding-top: 2em; }
    .netlegend dt a {
      display: block;
      position: relative;
      z-index: 1; }
    .netlegend dt h2,
    .netlegend dt h3,
    .netlegend dt h4 {
      margin: 0;
      margin-bottom: .1em;
      color: #000;
      font-weight: bold;
      max-width: calc(100% - 20px); }
      .netlegend dt h2 a,
      .netlegend dt h3 a,
      .netlegend dt h4 a {
        font-size: 1em;
        font-weight: bold; }
    .netlegend dt p {
      margin: .3em 0 0;
      font-size: 1.5em;
      color: #333; }
  .netlegend dd {
    margin: 0 0 2em;
    padding: 0;
    background-color: #fff;
    color: #000;
    overflow: hidden;
    font-size: 1em; }
    .netlegend dd div {
      padding-top: 1em; }
      .netlegend dd div p {
        margin: 0 0 .8em 0;
        font-size: 1.8em;
        max-width: 75ch; }
    .netlegend dd .media {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 2em; }
      .netlegend dd .media figure {
        flex: 0 1 auto;
        margin: 0 1em 0 0;
        padding: 0;
        min-width: 300px; }
        .netlegend dd .media figure figcaption {
          margin-top: .5em;
          font-size: 1.4em;
          color: #aaa; }
    .netlegend dd ul {
      list-style: none;
      margin: 0 0 1em 1.25em;
      padding: 0; }
      .netlegend dd ul li {
        margin-bottom: .5em;
        position: relative; }
    .netlegend dd > ul:first-child {
      margin-top: 1em; }
  .netlegend dl.boxAccordion dd {
    max-height: 0;
    transition: max-height 500ms ease; }
  .netlegend dl.boxAccordion {
    max-height: inherit; }
  .netlegend a:target + dt + dd div.txt {
    max-height: inherit; }
    .netlegend a:target + dt + dd div.txt:after,
    .netlegend a:target + dt + dd div.txt a.more {
      display: none; }
  .netlegend a:target + dt:before {
    transform: rotate(90deg); }
  .netlegend a:target + dt:after {
    transform: rotate(180deg); }
  .netlegend a:target + dt + dd {
    max-height: 2000px; }
  .netlegend .register {
    display: flex;
    margin: 0 0 1em 0;
    padding: 0;
    list-style: none; }
    .netlegend .register li {
      flex: 0 0 auto;
      padding: 0;
      margin-right: 4px; }
      .netlegend .register li:before {
        content: none; }
      .netlegend .register li a {
        border: 1px solid #0fa26e;
        display: inline-block;
        font-weight: bold;
        line-height: 1.2em;
        padding: .2em .4em;
        text-transform: uppercase;
        transition: background 200ms; }
        .netlegend .register li a:hover {
          background-color: #028a5a;
          color: #fff; }
  .netlegend ul.anchor {
    list-style: none;
    margin: 0 0 2em;
    padding: 0;
    margin-bottom: 3em; }
    .netlegend ul.anchor li {
      margin-bottom: .1em;
      position: relative; }
  .netlegend ul a[href^="#"]:after,
  .netlegend dt a[href^="#"]:after,
  .netlegend .related a[href^="#"]:after {
    content: none; }
  .netlegend .rating {
    display: flex;
    width: 100%;
    margin-bottom: 1em; }
    .netlegend .rating a {
      border-radius: 5px;
      border: 1px solid #028a5a;
      display: inline-block;
      font-size: 1.4em;
      margin-right: 1em;
      padding: .3em .5em;
      text-align: center; }
  .netlegend .related {
    margin-bottom: 2em;
    font-size: 1.6em; }
    .netlegend .related a {
      font-size: 1em; }

.f3-widget-paginator {
  margin: 1em 0;
  padding: 0;
  display: flex;
  list-style: none;
  justify-content: center; }
  .f3-widget-paginator li:before {
    content: none;
    padding: 0; }
  .f3-widget-paginator li {
    margin: 0 2px; }
    .f3-widget-paginator li a:link,
    .f3-widget-paginator li a:visited {
      background-color: #028a5a;
      border-radius: 5px;
      color: #fff;
      display: block;
      font-weight: 400;
      padding: .4em .6em;
      transition: background 250ms; }
      .f3-widget-paginator li a:link:hover,
      .f3-widget-paginator li a:visited:hover {
        background-color: #0fa26e;
        color: #fff; }
    .f3-widget-paginator li.current {
      border-radius: 5px;
      border: 1px solid #cecece;
      font-size: 1.6em;
      line-height: 2.2em;
      display: inline-block;
      text-align: center; }
    .f3-widget-paginator li.prev a,
    .f3-widget-paginator li.next a {
      min-width: 80px; }

main ul.f3-widget-paginator li {
  padding-left: inherit;
  text-align: center;
  position: inherit;
  min-width: 44px; }
  main ul.f3-widget-paginator li:before {
    content: none; }

/************************************************
* boxCategories (News Listing)
*************************************************/
main .boxCategories {
  border: 1px solid #e8e8e8; }
  main .boxCategories .title {
    background-color: #333;
    color: white;
    text-transform: uppercase;
    font-size: 1.5rem;
    padding: 1rem;
    text-align: center; }
  main .boxCategories ul {
    padding: 0 2rem;
    margin: 0; }
    main .boxCategories ul li {
      border-bottom: 1px solid #e8e8e8;
      padding: 0; }
      main .boxCategories ul li::before {
        content: none; }
      main .boxCategories ul li a {
        color: #555;
        display: block;
        padding: 2rem;
        position: relative; }
        main .boxCategories ul li a::before {
          content: '\e80f';
          color: #028a5a;
          transition: all ease .5s;
          position: absolute;
          left: 0; }
        main .boxCategories ul li a:hover {
          color: #028a5a; }
      main .boxCategories ul li:last-child {
        border-bottom: none; }

/************************************************
* Formulare
*************************************************/
.formElement {
  width: 66.10169%;
  float: left;
  margin-right: 1.69492%;
  margin-bottom: 3rem;
  /*********************************************
	 * Grid Row
	 */
  /* Label */
  /* Fieldset */
  /* Input & Textarea */
  /* file input */
  /* Radio Buttons */
  /* Checkboxen */
  /* Submit */ }
  .formElement h1 {
    text-align: center;
    margin: 0 0 5rem 0; }
  .formElement h2 {
    margin: 0; }
  .formElement p {
    margin: 0; }
  .formElement .form-group {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .formElement .row {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2rem;
    /* 2 Elemente in einer Grid Row */ }
    .formElement .row > .col-xs-6.col-sm-6.col-md-6.col-lg-6 {
      width: 48.71795%;
      float: left;
      margin-right: 2.5641%; }
      .formElement .row > .col-xs-6.col-sm-6.col-md-6.col-lg-6:last-child {
        width: 48.71795%;
        float: right;
        margin-right: 0; }
    .formElement .row + .row h2 {
      margin-top: 3rem; }
  .formElement label {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    color: #555;
    font-size: 1.8rem;
    margin-bottom: 1rem; }
  .formElement fieldset {
    margin: 0 0 2rem 0;
    padding: 0;
    border: 0; }
    .formElement fieldset legend {
      font-size: 2rem;
      font-weight: 600;
      color: #555;
      margin-bottom: 2rem;
      padding: 0; }
    .formElement fieldset > .form-group,
    .formElement fieldset .clearfix {
      margin-bottom: 1rem; }
  .formElement .input {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .formElement .input input,
    .formElement .input textarea {
      border: 1px solid black;
      padding: 1rem;
      font-size: 1.8rem;
      width: 100%;
      font-family: 'Source Sans Pro', sans-serif; }
    .formElement .input textarea {
      height: 100%; }
  .formElement .fileWrapper {
    position: relative; }
    .formElement .fileWrapper label {
      display: block;
      height: 100%;
      font-size: 1.8rem;
      padding: 1rem 1rem 1rem 4rem;
      width: 100%;
      position: absolute; }
      .formElement .fileWrapper label:before {
        content: '\e807';
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%); }
      .formElement .fileWrapper label.selected:before {
        content: '\f0f6'; }
    .formElement .fileWrapper .input {
      border: 1px solid black; }
    .formElement .fileWrapper input {
      opacity: 0;
      visibility: hidden; }
    .formElement .fileWrapper > span {
      width: 100%;
      display: block; }
  .formElement .inputs-list .form-check {
    width: 23.07692%;
    float: left;
    margin-right: 2.5641%; }
    .formElement .inputs-list .form-check:nth-of-type(4n) {
      width: 23.07692%;
      float: right;
      margin-right: 0; }
  .formElement .inputs-list .form-group {
    padding: 0; }
  .formElement .inputs-list label.form-check-label input[type="radio"] {
    width: auto;
    display: inline-block;
    margin-bottom: .2rem;
    margin-right: 1rem; }
  .formElement .input.checkbox > div {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .formElement .input.checkbox .form-check {
    width: 48.71795%;
    float: left;
    margin-right: 2.5641%; }
    .formElement .input.checkbox .form-check:nth-of-type(2n) {
      width: 48.71795%;
      float: right;
      margin-right: 0; }
  .formElement .input.checkbox label {
    vertical-align: bottom; }
  .formElement .input.checkbox span {
    font-size: 1.8rem; }
  .formElement .input.checkbox input[type="checkbox"] {
    width: auto;
    display: inline-block;
    margin-bottom: .2rem;
    margin-right: 1rem; }
  .formElement .actions {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    text-align: right; }
    .formElement .actions button {
      border: 1px solid black;
      color: black;
      line-height: 1.5;
      background: transparent none;
      cursor: pointer;
      font-size: 1.8rem;
      font-weight: 400;
      padding: 1.5rem 4rem;
      transition: .3s ease-in-out; }
      @media (min-width: 1025px) {
        .formElement .actions button:hover {
          background-color: #028a5a;
          color: white; } }

/************************************************
 * Select 2 Stylings überschreiben
 ************************************************/
.select2-container--default {
  font-size: 1.6rem;
  width: 100%;
  /* Hintergrund für aktive Auswahl*/
  /* Hintergrund für gehoverte Auswahl*/ }
  .select2-container--default .select2-selection--single {
    border: 1px solid black;
    font-size: 1.8rem;
    border-radius: 0;
    padding: .7rem .7rem .8rem .7rem;
    padding-right: 4rem;
    height: auto; }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
      height: auto;
      bottom: 0;
      width: 4rem;
      top: 0;
      right: 0;
      border: 1px solid black;
      border-left: 0; }
      .select2-container--default .select2-selection--single .select2-selection__arrow b {
        border: none; }
        .select2-container--default .select2-selection--single .select2-selection__arrow b::after {
          content: '\f107';
          position: absolute;
          top: -.5rem;
          left: -.8rem;
          font-size: 1.6rem;
          color: #565656; }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: #565656;
      font-size: 1.8rem;
      padding: 0; }
      .select2-container--default .select2-selection--single .select2-selection__rendered li:before {
        content: ''; }
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border: none; }
    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b::after {
      content: '\f106'; }
  .select2-container--default .select2-results__option {
    padding: 1rem;
    color: #565656; }
  .select2-container--default .select2-results__option[aria-selected="true"] {
    background-color: #e8e8e8; }
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #028a5a;
    color: white; }
  .select2-container--default .select2-selection--multiple {
    border: 1px solid black;
    font-size: 1.8rem;
    border-radius: 0;
    padding: .8rem .7rem .7rem .7rem;
    padding-right: 4rem;
    height: auto;
    line-height: 1.8rem; }
    .select2-container--default .select2-selection--multiple li {
      line-height: 1.5;
      font-size: 1.8rem; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice {
      margin-top: .2rem; }
    .select2-container--default .select2-selection--multiple .select2-search--inline::before {
      content: ''; }
      .select2-container--default .select2-selection--multiple .select2-search--inline::before input {
        font-size: 1.8rem; }

/* Hintergrund generell für die Optionen */
.select2-dropdown {
  background-color: white;
  border-color: black;
  border-width: 1px;
  border-radius: 0; }

/************************************************
 * RESPONSIVE
 ************************************************/
@media (max-width: 1024px) {
  .formElement {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; } }

@media (max-width: 650px) {
  .formElement .row > .col-xs-6.col-sm-6.col-md-6.col-lg-6,
  .formElement .row > .col-xs-6.col-sm-6.col-md-6.col-lg-6:last-child {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .formElement .row > .col-xs-6.col-sm-6.col-md-6.col-lg-6:last-child {
    margin-top: 2rem; }
  .formElement .input.checkbox .form-check,
  .formElement .input.checkbox .form-check:nth-of-type(2n) {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; } }

/************************************************
* Buttons
*************************************************/
button,
a.button {
  display: inline-block;
  padding: 1rem 2rem 1rem;
  background-color: #028a5a;
  color: white;
  transition: all ease .5s;
  font-size: 1.5rem;
  border: 0;
  text-transform: uppercase;
  cursor: pointer; }
  @media (min-width: 1025px) {
    button:hover,
    a.button:hover {
      background-color: #03bc7b; } }
  button.white,
  a.button.white {
    background-color: white;
    color: #028a5a; }
  button.more-btn::after,
  a.button.more-btn::after {
    content: '\e810';
    margin-left: 1rem; }

/************************************************
* .newsList
*************************************************/
.newsList.listing {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem; }
  .newsList.listing .f3-widget-paginator {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    text-align: center; }
    .newsList.listing .f3-widget-paginator li {
      display: inline-block; }
      .newsList.listing .f3-widget-paginator li:before {
        content: ''; }
  .newsList.listing article {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 3rem;
    border-bottom: 1px #f7f7f7 solid;
    padding-bottom: 4rem; }
    .newsList.listing article figure {
      width: 40.67797%;
      float: left;
      margin-right: 1.69492%;
      margin: 0; }
      .newsList.listing article figure img {
        display: block; }
    .newsList.listing article .text {
      width: 57.62712%;
      float: right;
      margin-right: 0;
      padding-left: 2rem; }
      .newsList.listing article .text time {
        color: #808080;
        font-size: 1.6rem; }
      .newsList.listing article .text h3 {
        color: #555;
        margin-top: 1rem; }
      .newsList.listing article .text p {
        color: #808080;
        margin-bottom: 3rem;
        padding-right: 1rem; }
      .newsList.listing article .text .more-btn {
        text-transform: uppercase;
        float: right; }
    .newsList.listing article a.button {
      float: right; }
      .newsList.listing article a.button:hover {
        background-color: #0fa26e;
        color: white; }

.newsList.start {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem; }
  .newsList.start h2 {
    text-align: center; }
  .newsList.start article {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%; }
    .newsList.start article:nth-child(4) {
      width: 23.72881%;
      float: right;
      margin-right: 0; }
  .newsList.start a {
    display: block; }
    @media (min-width: 1025px) {
      .newsList.start a:hover .image img {
        opacity: 0.7; } }
  .newsList.start .image {
    position: relative; }
    .newsList.start .image img {
      display: block;
      transition: opacity 0.2s ease-in; }
    .newsList.start .image time {
      background-color: #333;
      padding: 1rem 1.5rem;
      display: inline-block;
      color: white;
      text-align: center;
      position: absolute;
      font-size: 1.4rem;
      text-transform: uppercase;
      letter-spacing: .1rem;
      position: absolute;
      left: 1rem;
      top: 1rem; }
      .newsList.start .image time span {
        display: block; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1100px) {
  .newsList.listing article figure {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
  .newsList.listing article .text {
    width: 66.10169%;
    float: right;
    margin-right: 0; }
  .newsList.start article {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .newsList.start article:nth-child(2n) {
      width: 49.15254%;
      float: right;
      margin-right: 0; } }

@media (max-width: 900px) {
  .newsList.listing article figure {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2rem; }
  .newsList.listing article .text {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0; } }

@media (max-width: 580px) {
  .newsList.start article {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .newsList.start article:nth-child(2n) {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }

/************************************************
* .newsList
*************************************************/
.newsDetail {
  width: 65.90909%;
  float: left;
  margin-right: 2.27273%;
  margin-bottom: 3rem; }
  .newsDetail a[href^='https://www.youtube.'],
  .newsDetail a[href^='https://youtube.'] {
    position: relative;
    display: block; }
    .newsDetail a[href^='https://www.youtube.']::after,
    .newsDetail a[href^='https://youtube.']::after {
      content: '\f144';
      font-size: 8rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
  .newsDetail .text {
    margin-bottom: 3rem; }
  .newsDetail .images {
    margin-bottom: 3rem;
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .newsDetail .images a {
      width: 31.03448%;
      float: left;
      margin-right: 3.44828%;
      display: block;
      margin-bottom: 3rem; }
      .newsDetail .images a:nth-of-type(3n) {
        width: 31.03448%;
        float: right;
        margin-right: 0; }
    .newsDetail .images img {
      display: block; }
  .newsDetail .downloads {
    margin-bottom: 3rem; }
  .newsDetail a.button {
    margin: 1rem 0; }

/************************************************
* Responsive
*************************************************/
@media (max-width: 1400px) {
  .newsDetail {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .newsDetail .images a {
      width: 31.81818%;
      float: left;
      margin-right: 2.27273%; }
      .newsDetail .images a:nth-of-type(3n) {
        width: 31.81818%;
        float: right;
        margin-right: 0; } }

@media (max-width: 600px) {
  .newsDetail {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .newsDetail .images a {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%; }
      .newsDetail .images a:nth-of-type(3n) {
        width: 32.20339%;
        float: right;
        margin-right: 0; } }

@media (max-width: 450px) {
  .newsDetail .images a {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .newsDetail .images a:nth-of-type(3n) {
      width: 49.15254%;
      float: left;
      margin-right: 1.69492%; }
    .newsDetail .images a:nth-of-type(2n) {
      width: 49.15254%;
      float: right;
      margin-right: 0; } }

/************************************************
* .scheduler
*************************************************/
.scheduler {
  width: 66.10169%;
  float: left;
  margin-right: 1.69492%;
  margin-bottom: 3rem;
  /* Submit */ }
  .scheduler .title {
    font-size: 2rem;
    font-weight: 600;
    color: #555;
    margin-bottom: 2rem; }
  .scheduler .message {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .scheduler .calendar {
    width: 35.89744%;
    float: right;
    margin-right: 0; }
    .scheduler .calendar .inner {
      border: 1px solid #555; }
    .scheduler .calendar .selector {
      border-bottom: 1px solid #d5d5d5;
      text-align: center;
      padding: 2rem 1rem;
      color: #555;
      font-size: 1.6rem;
      position: relative; }
      .scheduler .calendar .selector .prev,
      .scheduler .calendar .selector .next {
        position: absolute;
        display: block;
        top: 50%;
        padding: 2rem 1rem;
        transform: translateY(-50%); }
      .scheduler .calendar .selector .prev {
        left: 1rem;
        content: '\f104'; }
      .scheduler .calendar .selector .next {
        right: 1rem;
        content: '\f105'; }
    .scheduler .calendar .overview {
      padding: 1.2rem 1rem; }
      .scheduler .calendar .overview .week {
        width: 100%;
        display: flex; }
      .scheduler .calendar .overview span {
        display: inline-block;
        padding: 1.5rem 1rem 1.2rem 1rem;
        text-align: center;
        width: 14.06%;
        margin: 1%;
        border-bottom: 0.2rem solid #028a5a; }
      .scheduler .calendar .overview .today {
        font-weight: 600; }
      .scheduler .calendar .overview .past {
        border-color: #DDDDDD; }
      .scheduler .calendar .overview .empty {
        border-color: white; }
      .scheduler .calendar .overview .reserved {
        color: #FF4136;
        border-color: #FF4136; }
  .scheduler form {
    width: 61.53846%;
    float: left;
    margin-right: 2.5641%; }
    .scheduler form .formField {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 2rem; }
      .scheduler form .formField.half {
        width: 47.36842%;
        float: left;
        margin-right: 5.26316%; }
        .scheduler form .formField.half.last {
          width: 47.36842%;
          float: right;
          margin-right: 0; }
    .scheduler form label {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      color: #555;
      font-size: 1.8rem;
      margin-bottom: 1rem; }
    .scheduler form input,
    .scheduler form textarea {
      border: 1px solid black;
      padding: 1rem;
      font-size: 1.8rem;
      width: 100%;
      font-family: 'Source Sans Pro', sans-serif; }
    .scheduler form textarea {
      height: 100%; }
  .scheduler .actions {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    text-align: right; }
    .scheduler .actions input[type="submit"] {
      border: 1px solid black;
      color: black;
      line-height: 1.5;
      background: transparent none;
      cursor: pointer;
      width: auto;
      font-size: 1.8rem;
      font-weight: 400;
      padding: 1.5rem 4rem;
      transition: .3s ease-in-out; }
      @media (min-width: 1025px) {
        .scheduler .actions input[type="submit"]:hover {
          background-color: green;
          color: white; } }

/************************************************
* Frontend Login Form (Händlerbereich)
*************************************************/
.tx-felogin-pi1 > div {
  font-size: 1.6rem;
  font-weight: 400;
  color: #808080;
  line-height: 1.5;
  margin-bottom: 2rem; }

.tx-felogin-pi1 form div {
  margin-bottom: 2rem; }

.tx-felogin-pi1 form label {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  color: #555;
  font-size: 1.8rem;
  margin-bottom: 1rem; }

.tx-felogin-pi1 form input {
  border: 1px solid black;
  padding: 1rem;
  font-size: 1.8rem;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif; }

.tx-felogin-pi1 form fieldset {
  border: 0;
  padding: 0; }

.tx-felogin-pi1 form legend {
  display: none; }

.tx-felogin-pi1 form input[type="submit"] {
  line-height: 1.5;
  background: transparent none;
  width: auto;
  display: inline-block;
  margin-bottom: .2rem;
  margin-right: 1rem;
  transition: .3s ease-in-out;
  cursor: pointer; }
  @media (min-width: 1025px) {
    .tx-felogin-pi1 form input[type="submit"]:hover {
      background-color: #028a5a;
      color: white; } }

/************************************************
* Fancybox Overlay
*************************************************/
.fancybox-bg {
  background-color: #fff; }

@keyframes showBanner {
  0% {
    transform: translateY(-2em);
    opacity: 0; }
  5% {
    transform: translateX(0);
    opacity: 0.95; }
  90% {
    transform: translateX(0);
    opacity: 0.95; }
  100% {
    transform: translateY(-2em);
    opacity: 0; } }

.typo3-messages {
  list-style: none;
  margin: 0;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%; }
  .typo3-messages li.alert {
    opacity: 0;
    transition: all 200ms;
    animation: showBanner 12s ease;
    background-color: #333;
    padding: 1em 2em; }
    .typo3-messages li.alert p {
      color: #fff;
      margin: 0;
      text-align: center;
      font-weight: bold; }
    .typo3-messages li.alert::before {
      content: none; }
    .typo3-messages li.alert.alert-success {
      background-color: #028a5a; }

/************************************************
* FOOTER
*************************************************/
/************************************************
* General FOOTER Stylings
*************************************************/
footer .wrap {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto; }
  footer .wrap:after {
    content: " ";
    display: block;
    clear: both; }

footer .left {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

footer .logo {
  margin-top: 2rem;
  float: right;
  width: 20rem; }

footer .upper {
  color: #cecece;
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  padding: 4rem 2rem;
  background: #333; }
  footer .upper .columns {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  footer .upper .column {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%; }
    footer .upper .column.last {
      width: 15.25424%;
      float: right;
      margin-right: 0; }
    footer .upper .column.double {
      width: 57.62712%;
      float: left;
      margin-right: 1.69492%; }
      footer .upper .column.double .column {
        width: 32.20339%;
        float: left;
        margin-right: 1.69492%; }
        footer .upper .column.double .column:last-child {
          width: 32.20339%;
          float: right;
          margin-right: 0; }
    footer .upper .column .columnTitle {
      font-size: 1.5rem;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 1.5rem; }
    footer .upper .column ul {
      margin-top: 1rem;
      list-style-type: none;
      padding: 0; }
    footer .upper .column li {
      text-align: left;
      line-height: 1.4;
      margin-bottom: .3rem; }
      footer .upper .column li:before {
        font-size: 1rem;
        content: '\e80f';
        color: #028a5a;
        transition: all ease .5s; }
    footer .upper .column a {
      color: #cecece;
      transition: all ease .3s;
      font-size: 1.4rem; }
      footer .upper .column a:hover {
        color: #028a5a; }
    footer .upper .column address {
      font-size: 1.4rem;
      line-height: 1.6;
      font-style: normal; }
      footer .upper .column address p {
        color: #cecece;
        font-size: 1.4rem;
        margin-top: 0; }
      footer .upper .column address a {
        font-size: 1.4rem; }

footer .lower {
  background-color: #191919;
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  padding: 1rem 2rem; }
  footer .lower .footerNav {
    float: right;
    padding-top: 0.5rem; }
    footer .lower .footerNav ul {
      margin: 0;
      padding: 0; }
    footer .lower .footerNav li {
      display: inline-block;
      text-transform: uppercase;
      padding: 3px; }
      footer .lower .footerNav li:before {
        content: ''; }
      footer .lower .footerNav li:last-child a {
        margin-right: 0; }
    footer .lower .footerNav a {
      color: #cecece;
      transition: all ease .3s;
      margin-right: 1.5rem; }
  footer .lower .social {
    float: left; }
    footer .lower .social:after {
      content: "";
      display: table;
      clear: both; }
    footer .lower .social ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
    footer .lower .social li {
      display: inline-block; }
    footer .lower .social a {
      position: relative;
      border: 1.5px solid #191919;
      background-color: white;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      display: inline-block;
      color: #191919;
      margin: 5px;
      transition: all .3s ease;
      margin-right: 1.5rem; }
      footer .lower .social a::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      footer .lower .social a[href^='https://www.facebook.']::after {
        content: '\f09a'; }
      footer .lower .social a[href^='https://www.facebook.']:hover {
        background-color: #3b5998; }
        footer .lower .social a[href^='https://www.facebook.']:hover::after {
          color: white; }
      footer .lower .social a[href^='https://www.xing.']::after {
        content: '\f168'; }
      footer .lower .social a[href^='https://www.xing.']:hover {
        background-color: #046064; }
        footer .lower .social a[href^='https://www.xing.']:hover::after {
          color: white; }
      footer .lower .social a[href^='https://plus.google.']::after {
        content: '\f30f'; }
      footer .lower .social a[href^='https://plus.google.']:hover {
        background-color: #dc4e41; }
        footer .lower .social a[href^='https://plus.google.']:hover::after {
          color: white; }
      footer .lower .social a[href^='https://www.instagram.']::after {
        content: url("/fileadmin/ege/img/ege-instagram.svg");
        width: 1.4em;
        height: 1.4em; }
      footer .lower .social a[href^='https://www.instagram.']:hover {
        background-color: #444; }
        footer .lower .social a[href^='https://www.instagram.']:hover::after {
          content: url("/fileadmin/ege/img/ege-instagram-white.svg");
          color: white; }
      footer .lower .social a span {
        display: none; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 2000px) {
  footer .wrap {
    max-width: 84%; } }

@media (max-width: 1650px) {
  footer .wrap {
    max-width: 90%; } }

@media (max-width: 1400px) {
  footer .wrap {
    max-width: 95%; } }

@media (max-width: 1100px) {
  footer .wrap {
    max-width: 100%; } }

@media (max-width: 1170px) {
  footer div li:before {
    content: ''; } }

@media (max-width: 1024px) {
  footer .upper .column a:hover {
    color: #cecece; } }

@media (max-width: 900px) {
  footer .upper .columns {
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%; }
  footer .upper .column {
    width: 48.71795%;
    float: left;
    margin-right: 2.5641%;
    margin-bottom: 2rem; }
    footer .upper .column.double {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0; }
      footer .upper .column.double .column {
        width: 48.71795%;
        float: left;
        margin-right: 2.5641%; }
        footer .upper .column.double .column:last-child {
          width: 48.71795%;
          float: right;
          margin-right: 0; }
  footer .upper .logo {
    margin: 0;
    width: 23.72881%;
    float: right;
    margin-right: 0; } }

@media (max-width: 620px) {
  footer .upper .columns {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  footer .upper .column {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%;
    margin-bottom: 2rem; }
    footer .upper .column.last, footer .upper .column:nth-child(2n) {
      width: 49.15254%;
      float: right;
      margin-right: 0; }
    footer .upper .column.double {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0; }
      footer .upper .column.double .column {
        width: 49.15254%;
        float: left;
        margin-right: 1.69492%; }
  footer .upper .logo {
    margin-top: 2rem;
    width: 32.20339%;
    float: right;
    margin-right: 0; } }

@media (max-width: 500px) {
  footer .lower .footerNav {
    float: left; } }

@media (max-width: 430px) {
  footer .upper .columns {
    width: 74.57627%;
    float: left;
    margin-right: 1.69492%; }
  footer .upper .column {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    footer .upper .column.last, footer .upper .column:nth-child(2n) {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
    footer .upper .column.double {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
      footer .upper .column.double .column {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0; }
        footer .upper .column.double .column:last-child {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0; }
  footer .upper .logo {
    margin: 0;
    width: 23.72881%;
    float: right;
    margin-right: 0; }
  footer .lower .social {
    float: none; }
  footer .lower .footerNav {
    float: none; }
    footer .lower .footerNav li {
      display: block; } }

#privacyInner {
  padding: 1em 0;
  margin: 0 auto; }
  #privacyInner p {
    max-width: 95%;
    color: #fff;
    line-height: 1.3em;
    text-align: center; }
    #privacyInner p a {
      color: #fff; }
  #privacyInner #closePrivacy {
    background-color: white;
    color: #555;
    display: inline-block;
    padding: 0 .5em;
    cursor: pointer;
    text-decoration: none;
    margin-left: 1em; }
