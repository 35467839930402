/************************************************
* navMain
*************************************************/

	#navMain {
		float: left;
		@include clearfix;
	}

/* generelle Stylings für alle Ebenen */

	ul.flexnav {
		background-color:white;
		@include clearfix;
		/*padding:1rem;*/
		a {
			color:$first;
			display: block;
			font-size: 1.7rem;
			transition: all .3s ease-in;
			text-transform: uppercase;
			text-align: left;

			&.act {
				color: $first;
			}

			&:after {

			}

		}

		li {

		}

	}

/* spezielle Stylings erste Ebene */

	ul.flexnav > li {

		> a {
			padding: 1rem 1.5rem 1rem 1.5rem;
			text-align: left;

			@media (min-width: 1101px) {

				&:hover {
					color: $first;
					color:$linkPrimary;
					transition: all .6s;

					&:after, &:focus {
						transform: scaleX(1);
					}
				}

			}

			&.act {
				color: $linkPrimary;

				&:after {
				border-bottom:solid 1px $linkPrimary;
				display:block;
				content: '';
				transform: scaleX(1);  
				transition: transform 250ms ease-in-out;
				}
			}

			&:after {
				display:block;
				content: '';
				border-bottom: solid 1px $linkPrimary;  
				transform: scaleX(0);  
				transition: transform 250ms ease-in-out;

			}
		}

		}

/* spezielle Stylings zweite Ebene */

	ul.flexnav  li  ul {
		background-color:#f7f7f7;

		 li {
			 a {
				padding: .8rem 1.5rem;

				@media (min-width: 1101px) {
					&:hover {
						background-color:$linkPrimary;
						color:white;
					}
				}

				&.act,
				&.current {
					background-color:$linkPrimary;
					color:white;
				}
			}
		}
	}
	

/* spezielle Stylings dritte Ebene */

	ul.flexnav > li > ul > li > ul {
			
		> li {

			a {
				@media (min-width: 1101px) {
					&:hover {
						background-color:#05a26b;
						color:white;
					}
				}

				&.act {
					background-color:#05a26b;
					color:white;
				}
			}

		}

	}

	.menu-button {
		display: none;
		&::before{
			@extend %icons;
		}
	}


/* spezielle Stylings vierte Ebene */

	ul.flexnav > li > ul > li > ul > li > ul {
			
		> li {

			a {
				@media (min-width: 1101px) {
					&:hover {
						background-color:#10b67c;
					}
				}
				&.act {
					background-color:#10b67c;
					color:white;

				}
			}
		}
	}

/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width: 1024px) {

		ul.flexnav {

			a {
				padding: 1rem 1.4rem;
			}

		}

	}

	@media (max-width: 1100px) {

		#navWrapper {
			float: none;
			clear: both;
			margin: 0;
		}

		#navMain{
			float: none;
			clear: both;
		}

		.menu-button {
			position: relative;
			display: block;
			cursor: pointer;
			float: left;
			padding-left: 4rem;
			margin-top: .8rem;
			font-size: 1.8rem;
			&::before{
				content: '\f0c9';
				font-size: 2.4rem;
				position: absolute;
				display: block;
				left: 1rem;
				top: 50%;
				transform: translateY(-42%);
				color:$first;
			}
		}

		ul.flexnav-show {
			margin: 0;
			display: block;
		}

		ul.flexnav > li > a {
			&:after {
				margin-top: 0;
			}
			&.act {
				margin-top: 0;
			}
		}

		/* spezielle Stylings erste Ebene */

		ul.flexnav > li {

			margin-left: 0;

		}

		/* zweite Ebene */

		ul.flexnav > li > ul.flexnav-show {

			> li {
				margin-left: 2rem;

				> a {
					padding: 1rem 1.4rem;
				}

			}

		}

	}
