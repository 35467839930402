@keyframes showBanner {
    0% {
      transform: translateY(-2em);
      opacity: 0;
    }
    5% {
      transform: translateX(0);
      opacity: 0.95;
    }
    90% {
      transform: translateX(0);
      opacity: 0.95;
    }
    100% {
      transform: translateY(-2em);
      opacity: 0;
  }
}

.typo3-messages {
    list-style: none;
    margin: 0;
    padding: 0;
    position: fixed;   
    right: 0;
    top: 0;
    width: 100%;

    li.alert {
        opacity: 0;
        transition: all 200ms;
        animation: showBanner 12s ease;
        background-color: #333;
        padding: 1em 2em;
        p {
            color: #fff;
            margin: 0;
            text-align: center;
            font-weight: bold;
        }

        &::before {
            content: none;
        }

        &.alert-success {
            background-color: #028a5a;
        }
    }

}