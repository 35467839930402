/************************************************
* General FOOTER Stylings
*************************************************/

	footer {

		.wrap{
			@include container(1600px);
		}

		.left {
			@include span(full);
		}

		.logo {
			margin-top: 2rem;
			float: right;
			width: 20rem;
		}

		.upper {
			color: $footerPrimary;
			@include span(full);
			padding: 4rem 2rem;
			background:#333;

			.columns {
				@include span (full);
			}

			.column {
				@include span (3 of 12);

				&.last {
					@include span (2 of 12 last);
				}

				&.double {
					@include span (7 of 12);
					.column {
						@include span (4 of 12);
						&:last-child {
							@include span (4 of 12 last);
						}
					}
				}

				.columnTitle {
					font-size: 1.5rem;
					font-weight: 600;
					text-transform: uppercase;
					margin-bottom:1.5rem;
				}

				ul {
					margin-top: 1rem;
					list-style-type: none;
					padding: 0;
				}

				li {
					text-align: left;
					line-height: 1.4;
					margin-bottom: .3rem;

					&:before {
						font-size: 1rem; 
						@extend %icons;
						content: '\e80f';
						color: $linkPrimary;
						transition: all ease .5s;
					}
				}

				a {
					color: $footerPrimary;
					transition: all ease .3s;
					font-size: 1.4rem;

					&:hover {
						color: $linkPrimary;
					}
				}

				address {
					font-size: 1.4rem;
					line-height: 1.6;
					font-style: normal;
					p{
						color: $footerPrimary;
						font-size: 1.4rem;
						margin-top: 0;
					}
					a{
						font-size: 1.4rem;
					}
				}

			}

		}

		.lower {
			background-color:#191919;
			@include span(full);
			padding: 1rem 2rem;

			.footerNav {
				float: right;
				padding-top: 0.5rem;

				ul {
					margin: 0;
					padding: 0;
				}

				li {
					display: inline-block;
					text-transform: uppercase;
					padding: 3px;

					&:before {
						content: '';
					}
					&:last-child a{
						margin-right: 0;
					}
				}

				a {
					color:$footerPrimary;
					transition: all ease .3s;
					margin-right: 1.5rem;
				}

			}

			.social {

				float: left;
				@include clearfix;

				ul{
					list-style-type: none;
					margin: 0;
					padding: 0;
				}

				li {
					display: inline-block;
				}

				a {
					position: relative;
					border:1.5px solid #191919;
					background-color:white;
					border-radius:50%;
					width:3rem;
					height:3rem;
					display: inline-block;
					color:#191919;
					margin: 5px;
					transition: all .3s ease;
					margin-right:1.5rem;

					&::after {
						@extend %icons;
						position: absolute;
						top: 50%;
						left:50%;
						transform:translate(-50%, -50%);
					}

					&[href^='https://www.facebook.']::after{
						content:'\f09a';
					}

					&[href^='https://www.facebook.']:hover{
						background-color: #3b5998;
						&::after{
							color: white;
						}
					}

					&[href^='https://www.xing.']::after{
						content:'\f168';
					}

					&[href^='https://www.xing.']:hover{
						background-color: #046064;
						&::after{
							color: white;
						}
					}

					&[href^='https://plus.google.']::after{
						content: '\f30f';
					}

					&[href^='https://plus.google.']:hover{
						background-color: #dc4e41;
						&::after{
							color: white;
						}
					}

					&[href^='https://www.instagram.']::after{
						content: url('/fileadmin/ege/img/ege-instagram.svg');
						width:  1.4em;
						height: 1.4em;
					}

					&[href^='https://www.instagram.']:hover{
						background-color: #444;
						&::after{
							content: url('/fileadmin/ege/img/ege-instagram-white.svg');
							color: white;
						}
					}

					span {
						display: none;
					}

				}

			} 

		}
	}



/************************************************
* RESPONSIVE
*************************************************/

@media (max-width:2000px) {

	footer .wrap {
		max-width: 84%;
	}

}

@media (max-width:1650px) {

	footer .wrap {
		max-width: 90%;
	}

}

@media (max-width:1400px) {

	footer .wrap {
		max-width: 95%;
	}

}

@media (max-width:1100px) {

	footer .wrap {
		max-width: 100%;
	}

}

@media (max-width:1170px) {
	footer {
		div {
			li {
				&:before {
					content:'';
				}
			}
		}
	}
}

@media (max-width: 1024px) {
	footer .upper .column a:hover {
		color: $footerPrimary;
	}
}


@media (max-width:900px) {

	footer {
		.upper {

			.columns {
				@include span(8 of 12);
			}

			.column {
				@include span (4 of 8);
				margin-bottom: 2rem;

				&.last,
				&:nth-child(2n) {
					// @include span (4 of 8 last);
				}

				&.double {
					@include span (full);
					margin-bottom: 0;
					.column {
						@include span (4 of 8);
						&:last-child {
							@include span (4 of 8 last);
						}
					}
				}
			}

			.logo {
				margin: 0;
				@include span(3 of 12 last);
			}

		}
	}

}

@media (max-width:620px) {

	footer {
		.upper {

			.columns {
				@include span(full);
			}

			.column {
				@include span (6 of 12);
				margin-bottom: 2rem;

				&.last,
				&:nth-child(2n) {
					@include span (6 of 12 last);
				}

				&.double {
					@include span (full);
					margin-bottom: 0;
					.column {
						@include span (6 of 12);
						// &:last-child {
						// 	@include span (6 of 12 last);
						// }
					}
				}
			}

			.logo {
				margin-top: 2rem;
				@include span(4 of 12 last);
			}

		}
	}

}

@media (max-width: 500px) {

	footer{

		.lower {

			.footerNav {
				float: left;
			}

		}

	}

}

@media (max-width: 430px) {

	footer {
		.upper {

			.columns {
				@include span(9 of 12);
			}

			.column {
				@include span (full);

				&.last,
				&:nth-child(2n) {
					@include span (full);
				}

				&.double {
					@include span (full);
					.column {
						@include span (full);
						&:last-child {
							@include span (full);
						}
					}
				}
			}

			.logo {
				margin: 0;
				@include span(3 of 12 last);
			}

		}

		.lower {

			.social {
				float: none;
			}

			.footerNav {
				float: none;
				li {
					display: block;
				}

			}

		}

	}

}







