/************************************************
* General CONTENT Stylings
*************************************************/

.header-info {
	@include span(full);
	position: relative;
	color: $textPrimary;
	line-height: 2rem;
	font-size: 1.4rem;
	border-bottom: 1.5px solid #dedede;

	.boxInfo {

		margin-top: 2rem;
		padding-left: 7rem;
		position: relative;

		&.phone{
			float: right;
			margin-right: 2rem;
		}

		&.cert{
			float: right;
			margin-right: 2rem;
			padding-left: 0;
			width: 20%;
			p:first-child::before {
				content: url(/fileadmin/ege/img/ege-made-in-germany.svg);
				display: inline-block;
				height: 50px;
				width: 50px;
				float: left;
				margin-right: 1em;
			}
		}

		i {
			font-size: 5rem;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			color: $linkPrimary;
			line-height: 1;
			display: block;
		}

		p {
			margin: 0;
		}
	}

	a.logo {
		display: block;
		float: left;
		padding: 1rem 2rem;
		img{
			width: 25rem;
		}
	}

}

header{
	position: relative;
	@include clearfix;

	.service-nav{
		float: right;		
	}

	a.login {
		font-size: 2.4rem;
		display: block;
		float: left;
		/*position: absolute;
		right: 2rem;
		bottom: .3rem;*/
		padding: .4rem;
		z-index: 10;
	}
}

html[lang="de"] header a.login {
	&::after {
		content: 'Händler-Login';
		font-size: 1.6rem;
		display: inline-block;
		padding: .3em 0 0 .2em;
		float: right;
	}
}

.header-nav {
	@include span(full);

	nav {
		@include span(8 of 12);

		ul {
			padding-left: 0;
		}

	}

	.nav-icons {
		@include span(4 of 12 last);
		text-align: right;

		a {
			display: inline-block;
			padding: 1.5rem;
			font-size: 2rem;
			color: $textPrimary;
		}

	}

}

/************************************************
* RESPONSIVE
*************************************************/

@media (max-width: 1100px) {
	header{
		.service-nav{
			padding-right: 1rem;
		}
	}
}

@media (max-width: 1024px) {
	.header-info {
		margin-top: 1rem;

		a.logo img{
			width: 18rem;
		}
	}	
}

@media (max-width:880px) {
	.header-info {

		.boxInfo {
			margin-top: 1.5rem;
			padding-left: 5rem;
			p, a{
				font-size: 1.5rem;
			}
			i {
				font-size: 4rem;
			}
		}

		a.logo {
			img{
				width: 20rem;
			}
		}

	}

}

@media (max-width:760px) {

	.header-info {

		.boxInfo {
			margin-top: 1.5rem;
			padding-left: 3.7rem;
			p, a{
				font-size: 1.3rem;
			}
			i {
				font-size: 3rem;
			}
		}

		a.logo {
			img{
				width: 17rem;
			}
		}

	}

}

@media (max-width:660px) {

	.header-info {

		margin-top: 0;

		.boxInfo {

			padding-left: 1.7rem;
			margin-left: 2rem;

			&.phone{
				float: none;
				margin-right: 0;
				margin-bottom: 1rem;
				p{
					display: inline-block;
					margin-right: 1rem;
				}
			}

			&.cert{
				margin-left: 0;
			}

			p, a{
				font-size: 1.3rem;
			}
			i {
				font-size: 1.5rem;
			}
		}

		a.logo {
			img{
				width: 17rem;
			}
		}

	}

}

@media (max-width: 420px) {

	.header-info {

		.boxInfo {

			&.cert{
				display: none
			}

		}

		a.logo {
			float: none;
			text-align: center;
			img{
				width: 17rem;
			}
		}

	}
}

@media (max-width:480px) {
	.header-info {

	}

}