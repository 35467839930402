
/************************************************
* FONT
*************************************************/
// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');
/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/source-sans-pro-v21-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/source-sans-pro-v21-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v21-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v21-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/source-sans-pro-v21-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/source-sans-pro-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v21-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/source-sans-pro-v21-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/source-sans-pro-v21-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v21-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v21-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/source-sans-pro-v21-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/source-sans-pro-v21-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v21-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}


/************************************************
* Base Font Setting
*************************************************/

  html{
    font-size: 62.5%; // 1 rem = 10px
  }

body{
    font-family: 'Source Sans Pro', sans-serif;
}

/************************************************
 * TYPO
 ************************************************/

  h1 {
    font-size:2.6rem;
    font-weight: 600; 
    color:$first;
    position: relative;
    margin:3rem 0;

    &::after {
      content: '';
      position: absolute;
      border-bottom: 2px solid $linkPrimary;
      width:7rem;
      bottom:0;
      left:0;
      top:3.5rem;
      margin-top:0.7rem;
    }
  }
  
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    color: $first;
  }

  h3 {
    font-size: 1.9rem;
    font-weight:600;
    color: $first;
  }

  h4 {
    font-size: 1.6rem;
    font-weight:400;
    color: $first;
  }

  p {
    font-size: 1.6rem;
    font-weight: 400;
    color: $textPrimary;
    line-height: 1.5;
    &.small {
      font-size: 1.2rem;
      font-weight: 300;
    }
    &.big {
      font-size: 1.8rem;
      font-weight: 600;
      a {
        font-size: 1.8rem;
        font-weight: 600;
      }
    }
    &.indent {
      padding-left: 2rem;
    }
  }

  strong {
    font-weight: 600;
  }

  a {
    font-size: 1.6rem;
    font-weight: 400;
    text-decoration:none;
    color: $linkPrimary;
  
    &:hover {
      color: $first;
    }

    /* links to files */

    &.filelink{
      position: relative;
      padding-left: 2rem;
      &::before{
        @extend %icons;
        position: absolute;
        left: 0;
        top: .1rem;
      }

      &[href$=".txt"]::before { 
        content: '\f0f6';
      }

      &[href$=".pdf"]::before { 
        content: '\f1c1';
      }

      &[href$=".doc"]::before,
      &[href$=".docx"]::before { 
        content: '\f1c2';
      }

      &[href$=".xls"]::before,
      &[href$=".xlsx"]::before { 
        content: '\f1c3';
      }

      &[href$=".ppt"]::before,
      &[href$=".pptx"]::before { 
        content: '\f1c4';
      }

      &[href$=".jpg"]::before { 
        content: '\f1c5';
      }

      &[href$=".zip"]::before { 
        content: '\f1c6';
      }

    }

  }

  /* anchor links */

  main a[href^='#']::after{
    @extend %icons;
    content: '\f149';
  }

  a.anchorTarget{
    @include span(full);
  }

  li {
    font-size: 1.6rem;
    font-weight: 400;
    color: $textPrimary;
  }

  span {
    font-size: 1.6rem;
    font-weight: 400;
  }

  ul.arrow {
    list-style-type: none;
    padding: 0;
    li {
      line-height: 1.5;
      padding-left: 2rem;
      position: relative;
      margin: 0;
      &:before {
        position: absolute;
        left: .5rem;
        top: .6rem;
        @extend %icons;
        content: '\e80f';
        color: $linkPrimary;
        transition: all ease .5s;
        font-size: 1rem;
      }
    }
  }


  /* RTE Editor classes */

  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }

/************************************************
* RESPONSIVE
************************************************/

  @media (max-width:1024px) {
    h1 {
      font-size: 2.4rem;
    }
  }

  @media (max-width:768px) {
    
    h2 {
      font-size: 2.1rem;
      font-weight: 600;
    }

    h3 {
      font-size: 1.8rem;
      font-weight:600;
    }
  }

  @media (max-width:480px) {
    h1 {
      font-size: 2.2rem;
      &::after {
        top:3rem;
      }
    }
  }
